import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { STORAGE_NAMES, SELECTORS, VIEW_TYPES } from '@root/app.config';
import { BlindData } from '@root/app.interfaces';
import { BackgroundService } from '@core/services/background-service/background.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';

declare const $: any;

@Component({
	selector: 'app-model-viewer',
	templateUrl: './model-viewer.component.html',
	styleUrls: ['./model-viewer.component.scss', './model-viewer.responsive.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelViewerComponent implements OnInit, OnDestroy {
	@ViewChild('rendererCanvas', { static: true })
	public rendererCanvas: ElementRef<HTMLCanvasElement>;
	@ViewChild('cursorMove', { static: true })
	public cursorMove: ElementRef<HTMLCanvasElement>;
	@ViewChild('cursorZoomIn', { static: true })
	public cursorZoomIn: ElementRef<HTMLCanvasElement>;
	@ViewChild('cursorZoomOut', { static: true })
	public cursorZoomOut: ElementRef<HTMLCanvasElement>;
	@ViewChild('cursorDrag', { static: true })
	public cursorDrag: ElementRef<HTMLCanvasElement>;
	@ViewChild('cursorRotate', { static: true })
	public cursorRotate: ElementRef<HTMLCanvasElement>;
	@ViewChild('modelContainer', { static: true })
	public modelContainer: ElementRef<HTMLCanvasElement>;

	isMobile = this.passDataService.isMobileOrTablet;
	hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
	currentBlindType: string;
	getCursorPointer: Subscription;
	getCursorGrab: Subscription;
	getModelLoaded: Subscription;
	getCurrentData: Subscription;
	getActionBackPage: Subscription;
	getCurrentBlindByType: Subscription;
	getViewType: Subscription;
	cursorCollapsed = false;
	isImageVisualisation: boolean;
	sessionSaved = !!this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config)?.session_key;
	loading = true;

	constructor(
		private engineService: EngineService,
		private shareServiceData: ShareService,
		private changeDetection: ChangeDetectorRef,
		private sessionStorageService: SessionStorageService,
		private passDataService: PassDataService,
		private backgroundService: BackgroundService,
	) {}

	ngOnInit(): void {
		this.isImageVisualisation = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.image_visualisation;
		this.engineService.init(this.rendererCanvas);
		this.loaderHandler();

		this.getCursorPointer = this.shareServiceData.getCursorPointer.subscribe(this.cursorPointerHandler.bind(this));
		this.getCurrentBlindByType = this.shareServiceData.getCurrentBlindByType.subscribe(this.blindTypeHandler.bind(this));
		this.getCurrentData = this.shareServiceData.currentData.subscribe(this.onAddNewBlindData.bind(this));
		this.getModelLoaded = this.shareServiceData.getModelLoaded.subscribe((status) => (this.loading = !status));
		this.getActionBackPage = this.shareServiceData.getActionBackPage.subscribe(this.onReturnFromOverview.bind(this));
		this.getViewType = this.shareServiceData.getViewType.subscribe(this.viewTypeHandler.bind(this));

		this.engineService.animate();
		this.hideCursorOnGrab();
	}

	ngOnDestroy(): void {
		this.getCursorPointer.unsubscribe();
		this.getCursorGrab.unsubscribe();
		this.getModelLoaded.unsubscribe();
		this.getCurrentData.unsubscribe();
		this.getActionBackPage.unsubscribe();
		this.getViewType.unsubscribe();
		this.getCurrentBlindByType.unsubscribe();
	}

	loaderHandler(): void {
		const blindDataStorage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const savedStatus = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved) || window.location.search;

		if (blindDataStorage || savedStatus) {
			this.loading = true;
		}

		this.changeDetection.detectChanges();
	}

	cursorPointerHandler(res): void {
		if (res.imageVisualisation || this.sessionSaved) {
			$(SELECTORS.cursor).hide();
			this.engineService.removeAfterRenderObservable();
		} else if (!this.cursorCollapsed) {
			this.engineService.setPositionForHTMLHints();

			$(this.cursorDrag.nativeElement).hide();
			$(this.cursorRotate.nativeElement).hide();

			res.cursor = res.cursor.split(', ').splice(-1, 1).join('');

			if (res.reset && $(this.cursorZoomIn.nativeElement).hasClass('active')) {
				$(this.cursorZoomIn.nativeElement).fadeIn();
			} else if (res.reset) {
				$(this.cursorMove.nativeElement).fadeIn().addClass('active');
			}

			if (res.cursor === 'grabbing' && res.clicked && !$(this.cursorZoomOut.nativeElement).hasClass('active')) {
				$(this.cursorMove.nativeElement).fadeOut().remove();
				$(this.cursorZoomIn.nativeElement).fadeIn().addClass('active');
			}

			if (res.zoomIn) {
				$(this.cursorMove.nativeElement).remove();
				$(this.cursorZoomIn.nativeElement).remove();
				$(this.cursorZoomOut.nativeElement).fadeIn().addClass('active');
			}

			if (res.zoomOut && $(this.cursorZoomOut.nativeElement).hasClass('active')) {
				$(this.cursorZoomOut.nativeElement).fadeOut().remove();
				this.cursorCollapsed = true;
				this.engineService.removeAfterRenderObservable();
			}
		}

		this.changeDetection.markForCheck();
	}

	hideCursorOnGrab(): void {
		this.getCursorGrab = this.shareServiceData.getCursorGrab.subscribe((res) => {
			if (res.cursor === 'default' || !res.clicked || this.sessionSaved) {
				return;
			}

			$(this.cursorMove.nativeElement).hide();
			$(this.cursorZoomIn.nativeElement).hide();
			$(this.cursorZoomOut.nativeElement).hide();
			$(this.cursorDrag.nativeElement).hide();
			$(this.cursorRotate.nativeElement).hide();

			this.changeDetection.markForCheck();
		});
	}

	onAddNewBlindData(data: BlindData[]): void {
		this.currentBlindType = data[data.length - 1].type;
		this.loading = true;

		this.changeDetection.detectChanges();
	}

	onReturnFromOverview(): void {
		const cursors = document.querySelectorAll(SELECTORS.cursor);

		cursors.forEach((element: HTMLElement) => element.remove());
		this.cursorCollapsed = true;
	}

	viewTypeHandler(type: string): void {
		this.isImageVisualisation = type === VIEW_TYPES.image_visualisation;
	}

	blindTypeHandler(type: string): void {
		this.loading = this.currentBlindType !== type;
		this.currentBlindType = type;

		this.changeDetection.markForCheck();
	}
}
