import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { ModalService } from '@core/services/modal/modal.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { STORAGE_NAMES } from '@root/app.config';

@Component({
	selector: 'app-link-unavailable-modal',
	templateUrl: './link-unavailable-modal.component.html',
	styleUrls: ['./link-unavailable-modal.component.scss', './link-unavailable-modal.responsive.scss'],
})
export class LinkUnavailableModalComponent implements OnInit {
	removedStatus = false;

	constructor(
		public dialogRef: MatDialogRef<LinkUnavailableModalComponent>,
		private sessionStorageService: SessionStorageService,
		private setupPageService: SetupPageService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA) public data: string,
	) {}

	ngOnInit() {
		this.modalService.liftUpModal(this.dialogRef);
		this.removedStatus = +this.data === 410;
	}

	closeDialog(): void {
		this.setupPageService.resetOnBeforeUnloadHandler();
		this.clearSessionStorage();

		window.history.pushState(null, null, '/home');
		window.location.reload();
	}

	clearSessionStorage(): void {
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_country_code);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_quiz_status);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_view_type);
	}
}
