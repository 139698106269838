import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SELECTORS } from '@root/app.config';

declare const $: any;

@Component({
	selector: 'app-retailer-info-modal',
	templateUrl: './retailer-info-modal.component.html',
	styleUrls: ['./retailer-info-modal.component.scss', './retailer-info-modal.responsive.scss'],
})
export class RetailerInfoModalComponent implements OnInit, OnDestroy {
	constructor(public dialogRef: MatDialogRef<RetailerInfoModalComponent>) {}

	ngOnInit() {
		$(SELECTORS.overlay_pane).addClass('landscape');
	}

	ngOnDestroy() {
		$(SELECTORS.overlay_pane).removeClass('landscape');
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
