<div #taskbarPanelBlock *ngIf="taskbarPanel">
	<button class="mc-taskbar" (click)="onTaskbarHandler($event)">
		<span class="mc-taskbar__name">{{ 'taskbar.title' | translate }}</span>
		<svg
			class="mc-taskbar__arrow"
			[class]="{ active: taskbarContainer }"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
		>
			<title>{{ 'taskbar.title' | translate }}</title>
			<g clip-path="url(#clip0_418_2468)">
				<path d="M17.875 11.6875L11 18.5625L4.125 11.6875" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M17.875 4.8125L11 11.6875L4.125 4.8125" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_418_2468">
					<rect width="22" height="22" fill="white" />
				</clipPath>
			</defs>
		</svg>
		<div *ngIf="taskbarHasUpdate" class="mc-taskbar__dot">Yellow Dot</div>
	</button>

	<div class="mc-taskbar__panel" *ngIf="taskbarContainer">
		<div class="mc-taskbar__item" *ngFor="let taskbarItem of taskbarItems">
			<div class="mc-taskbar__title">
				<span class="mc-taskbar__title--dot" *ngIf="taskbarItem.status">Yellow Dot</span>
				<span>{{ taskbarItem.title }}</span>
			</div>
			<button
				class="mc-taskbar__button"
				[class.active]="taskbarItem.active"
				[class]="taskbarItem.button.type"
				[id]="taskbarItem.id"
				(click)="onControlHandler($event)"
			>
				<span class="mc-taskbar__button--icon" [class]="taskbarItem.id">Icon</span>
				{{ taskbarItem.button.text }}
			</button>
		</div>
	</div>
</div>
