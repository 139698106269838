<div class="mc-color__builder" *ngFor="let item of materialData; let i = index">
	<div class="mc-color__item" *ngIf="blindType === 'outdoor'">
		<button *ngIf="item.id === 1" class="mc-color__item-button" [ngClass]="{'active': sunscreenStatus}"
				(click)="onSelectMesh(false)">{{ item.name }}
		</button>

		<button *ngIf="item.id === 2" class="mc-color__item-button" [ngClass]="{'active': cleanPVCStatus}"
				(click)="onClearPVC()">{{ item.name }}
		</button>

		<div #colorPicker class="mc-color__item-picker" *ngIf="item.id === 1"
			 [ngStyle]="{'background': 'linear-gradient(315deg,' + color +' 58.49%, ' + color + '80 100%)',
                                 'box-shadow': 'inset -4px -4px 20px rgba(0, 0, 0, 0.2)'}"
			 (click)="onSelectMesh(true)">
			<app-color-picker
					[data]="{id: item.id, name: item.name, opacity: 1, type: item.type}"
					[colors]="materialColors"
					(setColorData)="onChangeColor($event)"
					[placement]="'top bottom'"
			></app-color-picker>
		</div>

		<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
			  #popover="ngbPopover" #popoverButton
			  (shown)="onPopover(popover, popoverButton)"
			  (hidden)="onPopover(popover, popoverButton)"
			  placement="left right"
			  popoverClass="mc-popover mc-popover-blind-info"
			  [ngbPopover]="sessionText['material_pop_up_text_' + (i + 1)]"></span>
	</div>

	<div class="mc-color__item" *ngIf="blindType === 'interior'">
		<button class="mc-color__item-button" [ngClass]="{'active': type === item.type}"
				(click)="onInterior(i)">{{ item.name }}
		</button>

		<div #colorPicker class="mc-color__item-picker"
			 [ngStyle]="{'background': 'linear-gradient(315deg,' + item.color +' 58.49%, ' + item.color + '80 100%)',
                                 'box-shadow': 'inset -4px -4px 20px rgba(0, 0, 0, 0.2)'}"
			 (click)="onSelectMesh(true)">
			<app-color-picker
					[data]="{id: item.id, name: item.name, opacity: 1, type: item.type}"
					[colors]="materialColors"
					(setColorData)="onChangeColor($event)"
					[placement]="'top bottom'"
			></app-color-picker>
		</div>

		<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
			  #popover="ngbPopover" #popoverButton
			  (shown)="onPopover(popover, popoverButton)"
			  (hidden)="onPopover(popover, popoverButton)"
			  placement="left right"
			  popoverClass="mc-popover mc-popover-blind-info"
			  [ngbPopover]="sessionText['material_pop_up_text_' + (i + 1) + (blindType === 'interior' ? '_interior' : '')]"></span>
	</div>
</div>


