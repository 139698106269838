<div class="mc-view-switcher" *ngIf="hasBlinds && !prodStatus" [ngClass]="{'iv-screen': !isDesignYourBlind}">
	<button class="mc-view-switcher-button"
			[ngClass]="{'active': isDesignYourBlind}"
			[disabled]="isDesignYourBlind"
			(click)="onSwitchViewMode(viewTypes.design)"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="right"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('view_switcher.DYB' | translate) : null"></button>
	<button class="mc-view-switcher-button"
			[ngClass]="{'active': !isDesignYourBlind}"
			[disabled]="!isDesignYourBlind"
			(click)="onSwitchViewMode(viewTypes.image_visualisation)"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="right"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('view_switcher.AR' | translate) : null"></button>
</div>
