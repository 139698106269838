import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ASSETS_LINKS } from '@root/app.config';
import { ModalService } from '@core/services/modal/modal.service';
import { ShareService } from '@core/services/share-data/share-data.service';

@Component({
	selector: 'app-ar-no-blinds-modal',
	templateUrl: './ar-no-blinds-modal.component.html',
	styleUrls: ['./ar-no-blinds-modal.component.scss', './ar-no-blinds-modal.responsive.scss']
})
export class ArNoBlindsModalComponent implements OnInit {

	imagesFolderUrl = ASSETS_LINKS.images;

	constructor(
		private dialogRef: MatDialogRef<ArNoBlindsModalComponent>,
		private modalService: ModalService,
		private shareService: ShareService
	) { }

	ngOnInit(): void {
		this.dialogRef.afterOpened().subscribe(this.modalService.liftUpModal.bind(this.modalService, this.dialogRef));
	}

	close(): void {
		this.dialogRef.close();
	}

	onSelect(): void {
		this.close();
		this.shareService.setAccordionMobileBlindList(true);
	}

}
