import { Injectable } from '@angular/core';
import { AbstractMesh, BoundingBoxGizmo, CreateSphere, Mesh, PointerDragBehavior, Vector3, VertexBuffer } from '@babylonjs/core';
import { Logger } from '@babylonjs/core/Misc/logger';
import { PivotTools } from '@babylonjs/core/Misc/pivotTools';
import { CreateBox } from '@babylonjs/core/Meshes/Builders/boxBuilder';

@Injectable({
	providedIn: 'root',
})
export class GizmoService {
	verticesDataCornerBox;

	constructor() {}

	updateScaleBoxDragBehavior(this: BoundingBoxGizmo, isImageVisualisation: boolean, gizmoService: GizmoService): void {
		gizmoService.getCornerMeshVerticesData(this.gizmoLayer);

		// @ts-ignore
		const scaleBoxes = this._scaleBoxesParent.getChildMeshes();
		let scaleBoxIndex = 0;

		for (let i = 0; i < 3; i++) {
			for (let j = 0; j < 3; j++) {
				for (let k = 0; k < 3; k++) {
					(() => {
						// create box for relevant axis
						const zeroAxisCount = (i === 1 ? 1 : 0) + (j === 1 ? 1 : 0) + (k === 1 ? 1 : 0);
						if (zeroAxisCount === 1 || zeroAxisCount === 3) {
							return 'continue';
						}

						if (zeroAxisCount === 0) {
							const cornerScaleBox = scaleBoxes[scaleBoxIndex] as Mesh;
							cornerScaleBox.setVerticesData(VertexBuffer.PositionKind, gizmoService.verticesDataCornerBox.positionKind);
							cornerScaleBox.setVerticesData(VertexBuffer.NormalKind, gizmoService.verticesDataCornerBox.normalKind);
							cornerScaleBox.setVerticesData(VertexBuffer.UVKind, gizmoService.verticesDataCornerBox.uvKind);
							cornerScaleBox.setIndices(gizmoService.verticesDataCornerBox.indices);
							cornerScaleBox.name = 'corner';

							const rotationVectors = {
								0: new Vector3(0, 0, 0),
								3: new Vector3(Math.PI, 0, 0),
								9: new Vector3(0, Math.PI, 0),
								12: new Vector3(Math.PI, Math.PI, 0),
							};

							if (rotationVectors[scaleBoxIndex]) {
								cornerScaleBox.rotationQuaternion = rotationVectors[scaleBoxIndex].toQuaternion();
							}
						} else {
							const scaleBox = scaleBoxes[scaleBoxIndex] as Mesh;
							scaleBox.forceSharedVertices();
						}

						const box = scaleBoxes[scaleBoxIndex++] as AbstractMesh;

						// Dragging logic
						const dragAxis = new Vector3(i - 1, j - 1, k - 1).normalize();
						const dragBehavior = box.behaviors[0] as PointerDragBehavior;
						dragBehavior.onDragObservable.clear();
						dragBehavior.onDragObservable.add((event) => onDragObservableHandler.call(this, event));

						function onDragObservableHandler(event) {
							this.onScaleBoxDragObservable.notifyObservers({});
							if (this.attachedMesh) {
								const originalParent = this.attachedMesh.parent;
								if (originalParent && originalParent.scaling && originalParent.scaling.isNonUniformWithinEpsilon(0.001)) {
									Logger.Warn('BoundingBoxGizmo controls are not supported on child meshes with non-uniform parent scaling');
									return;
								}

								if (isImageVisualisation) {
									this._anchorMesh.scaling = new Vector3(zeroAxisCount === 0 ? 1 : 2, 1, 1);
								}

								PivotTools._RemoveAndStorePivotPoint(this.attachedMesh);
								const relativeDragDistance = (event.dragDistance / this._boundingDimensions.length()) * this._anchorMesh.scaling.length();
								const deltaScale = new Vector3(relativeDragDistance, relativeDragDistance, 0);
								if (zeroAxisCount === 2) {
									// scale on 1 axis when using the anchor box in the face middle
									deltaScale.x *= Math.abs(dragAxis.x);
									deltaScale.y *= Math.abs(dragAxis.y);
									deltaScale.z *= Math.abs(dragAxis.z);
								}
								deltaScale.scaleInPlace(this._scaleDragSpeed);
								this.updateBoundingBox();
								if (this.scalePivot) {
									this.attachedMesh.getWorldMatrix().getRotationMatrixToRef(this._tmpRotationMatrix);
									// Move anchor to desired pivot point (Bottom left corner + dimension/2)
									this._boundingDimensions.scaleToRef(0.5, this._tmpVector);
									Vector3.TransformCoordinatesToRef(this._tmpVector, this._tmpRotationMatrix, this._tmpVector);
									this._anchorMesh.position.subtractInPlace(this._tmpVector);
									this._boundingDimensions.multiplyToRef(this.scalePivot, this._tmpVector);
									Vector3.TransformCoordinatesToRef(this._tmpVector, this._tmpRotationMatrix, this._tmpVector);
									this._anchorMesh.position.addInPlace(this._tmpVector);
								} else {
									// Scale from the position of the opposite corner
									box.absolutePosition.subtractToRef(this._anchorMesh.position, this._tmpVector);
									this._anchorMesh.position.subtractInPlace(this._tmpVector);
								}
								this._anchorMesh.addChild(this.attachedMesh);
								this._anchorMesh.scaling.addInPlace(deltaScale);
								if (this._anchorMesh.scaling.x < 0 || this._anchorMesh.scaling.y < 0 || this._anchorMesh.scaling.z < 0) {
									this._anchorMesh.scaling.subtractInPlace(deltaScale);
								}
								this._anchorMesh.removeChild(this.attachedMesh);
								this.attachedMesh.setParent(originalParent);
								PivotTools._RestorePivotPoint(this.attachedMesh);
							}
							this._updateDummy();
						}
					})();
				}
			}
		}
	}

	updateRotateSpheres(this: BoundingBoxGizmo) {
		const sphere = CreateSphere('', { diameter: 1 }, this.gizmoLayer.utilityLayerScene);
		const positionVerticesData = sphere.getVerticesData(VertexBuffer.PositionKind);
		const normalsVerticesData = sphere.getVerticesData(VertexBuffer.NormalKind);
		const uvVerticesData = sphere.getVerticesData(VertexBuffer.UVKind);
		const indices = sphere.getIndices();

		// @ts-ignore
		const rotateSpheres = this._rotateAnchorsParent.getChildMeshes();
		rotateSpheres.forEach((mesh: Mesh) => {
			mesh.setVerticesData(VertexBuffer.PositionKind, positionVerticesData);
			mesh.setVerticesData(VertexBuffer.NormalKind, normalsVerticesData);
			mesh.setVerticesData(VertexBuffer.UVKind, uvVerticesData);
			mesh.setIndices(indices);
		});

		sphere.dispose();
	}

	getCornerMeshVerticesData(gizmoLayer): void {
		if (!this.verticesDataCornerBox) {
			const boxY = CreateBox('', { width: 0.4, height: 1.6, depth: 0.4 }, gizmoLayer.utilityLayerScene);
			boxY.position.y = 0.6;
			const boxX = CreateBox('', { width: 1.6, height: 0.4, depth: 0.4 }, gizmoLayer.utilityLayerScene);
			boxX.position.x = 0.6;

			const cornerMesh = Mesh.MergeMeshes([boxX, boxY], true);
			this.verticesDataCornerBox = {
				positionKind: cornerMesh.getVerticesData(VertexBuffer.PositionKind),
				normalKind: cornerMesh.getVerticesData(VertexBuffer.NormalKind),
				uvKind: cornerMesh.getVerticesData(VertexBuffer.UVKind),
				indices: cornerMesh.getIndices(),
			};

			boxX.dispose();
			boxY.dispose();
			cornerMesh.dispose();
		}
	}
}
