import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CONFIG, ROTATE_TYPES, SELECTORS, VIEW_TYPES, STORAGE_NAMES } from '@root/app.config';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { ModalService } from '@core/services/modal/modal.service';
import { RotateDeviceModalComponent } from '@shared/modals/rotate-device-modal/rotate-device-modal.component';
import { ArLoadingModalComponent } from '@shared/modals/ar-loading-modal/ar-loading-modal.component';
import { BackgroundConfig } from '@root/app.interfaces';
import { IndexedDBService } from '@core/services/indexed-db/indexed-db.service';

@Component({
	selector: 'app-select-background-modal',
	templateUrl: './select-background-modal.component.html',
	styleUrls: ['./select-background-modal.component.scss', './select-background-modal.responsive.scss']
})
export class SelectBackgroundModalComponent implements OnInit, OnDestroy {
	@ViewChild('formElement') formElement: ElementRef;
	
	isMobile: boolean = this.passDataService.isMobileOrTablet;
	getProductType: string;
	ivMode: boolean;
	loading = false;
	selectedBackgroundType = '';
	allowedMimeType: string[] = ['image/png', 'image/jpeg', 'image/jpg'];
	maxFileSize = 10000000;
	maxSizeError = false;
	fileTypeError = false;
	abortController: AbortController;
	imageWidth: number;
	imageHeight: number;
	sampleStatus: boolean;
	sampleType: string;
	sampleIndex: number;
	sampleSavedSettings;
	form: FormGroup = this.fb.group({
		photo: null,
		photo_base64: null,
		photo_src: null,
		photo_blob: null
	});

	projectSamples = {};
	
	slideConfig = {
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: false,
		infinite: false,
		variableWidth: true,
		mobileFirst: false,
		arrows: false,
		responsive: [
			{
				breakpoint: CONFIG.breakpoints['phone'],
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1
				}
			}
		]
	};

	@HostListener('window:orientationchange', ['$event']) onOrientationChange() {
		if (this.selectedBackgroundType === 'project_sample' && this.form.value.photo_base64) {
			const sample = this.projectSamples[this.sampleType][this.sampleIndex];
			this.onSelectProjectSample(null, sample, this.sampleType, this.sampleIndex);
		}
	}
	
	constructor(
		private fb: FormBuilder,
		private engineService: EngineService,
		private shareService: ShareService,
		private setupPageService: SetupPageService,
		private sessionStorageService: SessionStorageService,
		private passDataService: PassDataService,
		private modalService: ModalService,
		private matDialog: MatDialog,
		private indexedDBService: IndexedDBService,
		public dialogRef: MatDialogRef<SelectBackgroundModalComponent>,

		@Inject(MAT_DIALOG_DATA) public data
	) {
	}
	
	ngOnInit() {
		this.getProductType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type) || 'outdoor';
		this.engineService.setGizmoControlVisible(false);
		this.modalService.liftUpModal(this.dialogRef);
		this.backgroundImagesHandler();
	}
	
	ngOnDestroy() {
		this.engineService.setGizmoControlVisible(true);
		this.setViewType();
	}

	backgroundImagesHandler(): void {
		const storageConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);

		if (storageConfig) {
			this.projectSamples = {...storageConfig?.iv_images};
		}
	}
	
	onSelectType(type: string) {
		const getProductType = this.setupPageService.getProductType();
		const screenType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type);
		this.selectedBackgroundType = type;
		this.scrollToForm();
		this.onDeleteFile();

		this.ivMode = screenType === VIEW_TYPES.image_visualisation && getProductType === 'all';

		if (this.selectedBackgroundType === 'upload') {
			this.abortController?.abort();
			this.setUploadedBackground();
		}
	}
	
	onSelectProjectSample(event: Event, sampleProject: string, type: string, index: number) {
		const target = event ? event.currentTarget as HTMLElement : null;

		this.sampleStatus = true;
		this.sampleType = type;
		this.sampleIndex = index;

		this.form.controls.photo.reset();
		this.form.controls.photo_base64.reset();
		this.form.controls.photo_src.reset();

		if (target) {
			const sampleList = document.querySelectorAll(SELECTORS.background_project_samples);
			sampleList.forEach((element: HTMLElement) => element.classList.remove('active'));

			target.classList.add('active');
		}

		this.abortController?.abort();
		this.abortController = new AbortController();
		const signal = this.abortController.signal;
		const orientation = this.setupPageService.getScreenOrientation();
		const id = `${type}_${index}_${orientation}`;

		this.indexedDBService.getImage(STORAGE_NAMES.zip_image_visualisation_background, id).then(image => {
			if (image) {
				this.uploadFile(image.blob);
			} else {
				this.loading = true;

				fetch(sampleProject.replace('/preview/', `/${orientation}_`), {signal})
				.then((res) => res.blob())
				.then((blob) => this.uploadFile(blob))
				.catch((err) => console.error(err));
			}

			this.scrollToElement(this.formElement.nativeElement.querySelector('button[type=submit]'));
		});
		
	}
	
	onSelectFile(event: Event) {
		const input = event.target as HTMLInputElement;
		const fileArray = this.convertFileList(input.files);
		
		if (fileArray.length) {
			this.sampleType = null;
			this.sampleIndex = null;
			this.uploadFile(fileArray[0]);
		}
		
		if (input.id === 'photo') {
			this.scrollToForm();
		}
	}
	
	onDropFile(event) {
		event.preventDefault();
		event.stopPropagation();
		event.target.classList.remove('drag-over');
		
		const fileArray = this.convertFileList(event.dataTransfer.files);
		
		if (fileArray.length) {
			this.uploadFile(fileArray[0]);
		}
	}

	setUploadedBackground() {
		const ivBackgroundImage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_uploaded_image_visualisation_background);

		if (ivBackgroundImage) {
			this.indexedDBService.getImage(STORAGE_NAMES.zip_image_visualisation_background, ivBackgroundImage.id).then(image => {
				this.sampleType = null;
				this.sampleIndex = null;
				this.sampleSavedSettings = { settings: ivBackgroundImage.settings, shutterValues: ivBackgroundImage.shutterValues};

				if (image) {
					this.uploadFile(image.blob);
				}
			});
		}
	}
	
	onDragEvent(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		
		if (event.type === 'dragover') {
			(event.target as HTMLElement).classList.add('drag-over');
		} else if (event.type === 'dragleave') {
			(event.target as HTMLElement).classList.remove('drag-over');
		}
	}
	
	convertFileList(files: FileList): File[] {
		this.fileTypeError = false;
		this.maxSizeError = false;
		
		return [...files].filter((file: File) => {
			const isAllowedType = this.allowedMimeType.some((type: string) => type === file.type);
			const isCorrectFileSize = file.size <= this.maxFileSize;
			
			if (!isAllowedType) {
				this.fileTypeError = true;
			} else if (!isCorrectFileSize) {
				this.maxSizeError = true;
			}
			
			return isAllowedType && isCorrectFileSize;
		});
	}
	
	uploadFile(file: File | Blob) {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.loading = false;
			this.form.controls.photo_base64.setValue(reader.result);
		};
		
		const image = new Image();
		image.src = window.URL.createObjectURL(file);
		image.onload = () => {
			this.form.controls.photo_src.setValue(image.src);
			this.form.controls.photo_blob.setValue(file);
			this.imageHeight = image.height;
			this.imageWidth = image.width;
		};
	}
	
	onDeleteFile(type?: string) {
		this.form.controls.photo.reset();
		this.form.controls.photo_base64.reset();
		this.form.controls.photo_src.reset();
		this.fileTypeError = false;
		this.maxSizeError = false;
		this.sampleSavedSettings = {};
		
		if (type === 'photo') {
			this.selectedBackgroundType = '';
			this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_iv_type);
		}
	}
	
	onSubmit() {
		if (this.form.valid) {
			const orientation = this.setupPageService.getScreenOrientation();
			const imageOrientation = this.imageWidth > this.imageHeight ? 'landscape' : 'portrait';

			const backgroundImage: BackgroundConfig = {
				src: this.form.value.photo_src,
				blob: this.form.value.photo_blob,
				base64: this.form.value.photo_base64,
				width: this.imageWidth,
				height: this.imageHeight,
				type: this.sampleType,
				index: this.sampleIndex,
				image_orientation: this.sampleType ? orientation : imageOrientation,
				id: this.sampleType ?  `${this.sampleType}_${this.sampleIndex}_${orientation}` : 'own_image'
			};

			if (!this.sampleType) {
				backgroundImage['settings'] = this.sampleSavedSettings.settings;
				backgroundImage['shutterValues'] = this.sampleSavedSettings.shutterValues;
			}

			this.sessionStorageService.setSession(this.selectedBackgroundType, STORAGE_NAMES.zip_iv_type);
			this.closeRotateDeviceModal();
			this.engineService.setBackgroundImage(backgroundImage);
			this.setViewType(VIEW_TYPES.image_visualisation);
			this.onClose();

			this.passDataService.isLiveOpened = false;
		}
	}
	
	onClose(): void {
		this.dialogRef.close();
	}
	
	scrollToForm() {
		if (window.orientation === 0 && window.innerWidth <= CONFIG.breakpoints['phone'] ||
			window.orientation === 90 && window.innerWidth <= CONFIG.breakpoints['tablet-landscape']) {
			setTimeout(this.scrollToElement.bind(this, this.formElement.nativeElement));
		}
	}
	
	trackByIndex(index: number): number {
		return index;
	}
	
	openLive(): void {
		const dialog = this.matDialog.open(ArLoadingModalComponent, this.modalService.getConfig(ArLoadingModalComponent.name));
		
		this.engineService.addVideoLayer().then(() => {
			this.onClose();
			dialog.close();
		}).catch(() => {
			dialog.close();
		});
	}
	
	openRotateDeviceModal(): void {
		const rotateType = window.innerWidth < window.innerHeight && this.imageWidth > this.imageHeight ? ROTATE_TYPES.to_landscape :
			window.innerWidth > window.innerHeight && this.imageWidth < this.imageHeight ? ROTATE_TYPES.to_portrait : '';
		
		if (this.isMobile && rotateType) {
			this.matDialog.open(
				RotateDeviceModalComponent,
				this.modalService.getConfig(RotateDeviceModalComponent.name, rotateType)
			);
		}
	}

	closeRotateDeviceModal(): void {
		const dialog = this.matDialog.getDialogById('rotate-device-modal');

		if (dialog) {
			dialog.close();
		}
	}
	
	scrollToElement(element: HTMLElement): void {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}
	
	setViewType(type?: string): void {
		type ||= this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type);
		this.shareService.setViewType(type);
	}
}
