<div class="mc-control-model">
	<div #popover="ngbPopover"
		 (click)="onControl()"
		 [ngClass]="{'active': active}"
		 [ngbPopover]="'control_panel.resize' | translate"
		 class="mc-control-model__button"
		 placement="right"
		 popoverClass="mc-popover mc-popover-image-visualisation"
		 triggers="mouseenter:mouseleave"></div>
</div>
