<div *ngIf="data.status" class="mc-blind-title" [innerHTML]="'modals.blind_type.title' | translate"></div>
<div class="mc-blind-modal-container" [class.internal]="!data.status">
	<div class="mc-blind-modal__title">
		{{ 'modals.blind_type.subtitle' | translate }}
	</div>
	<div #blindTypeContent class="mc-blind-modal">
		<button
			class="mc-blind-modal-button outdoor"
			[class.internal]="!data.status"
			[disabled]="clicked"
			(click)="onBlindTypeHandler('outdoor')"
		>
			{{ 'modals.blind_type.buttons.outdoor' | translate }}
		</button>
		<button
			class="mc-blind-modal-button interior"
			[class.internal]="!data.status"
			[disabled]="clicked"
			(click)="onBlindTypeHandler('interior')"
		>
			{{ 'modals.blind_type.buttons.interior' | translate }}
		</button>
	</div>
	<div class="mc-blind-modal-close" *ngIf="!data.status" (click)="onClose()"></div>
</div>
