import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ROTATE_TYPES } from '@root/app.config';
import { EngineService } from '@core/services/engine/engine.service';
import { ModalService } from '@core/services/modal/modal.service';

@Component({
	selector: 'app-rotate-device-modal',
	templateUrl: './rotate-device-modal.component.html',
	styleUrls: ['./rotate-device-modal.component.scss', './rotate-device-modal.responsive.scss'],
})
export class RotateDeviceModalComponent implements OnInit {
	rotateTypes = ROTATE_TYPES;
	orientation = this.getScreenOrientation();

	@HostListener('window:orientationchange', ['$event']) onOrientationChange() {
		if ((window.orientation === 0 || window.orientation === 180) && this.data === ROTATE_TYPES.to_portrait) {
			this.engineService.sceneOrientation = this.getScreenOrientation();
			this.dialogRef.close();
		}

		if ((window.orientation === 90 || window.orientation === -90) && this.data === ROTATE_TYPES.to_landscape) {
			this.engineService.sceneOrientation = this.getScreenOrientation();
			this.dialogRef.close();
		}
	}

	constructor(
		private modalService: ModalService,
		private engineService: EngineService,
		public dialogRef: MatDialogRef<RotateDeviceModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) {}

	ngOnInit(): void {
		this.modalService.liftUpModal(this.dialogRef);
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	getScreenOrientation(): string {
		return window.orientation === 0 || window.orientation === 180 ? 'portrait' : 'landscape';
	}

	resetScene(): void {
		this.dialogRef.close();
		this.engineService.boundingBoxesSettings = {};
		this.engineService.sceneOrientation = this.getScreenOrientation();
		this.engineService.setIVCameraSettings();
		this.engineService.setGizmoControl(true);
		this.engineService.setIVSampleProject();
		this.engineService.createModelsFromStorage();
		this.engineService.resetLineByPoints(false);
	}
}
