<div class="mc-color-picker"
	 #colorPickerPopover="ngbPopover"
	 [ngbPopover]="popContent"
	 [placement]="placement"
	 [autoClose]="'outside'"
	 container="body"
	 popoverClass="mc-popover mc-popover-color-picker"
>
	<ng-template #popContent>
		<div class="mc-color-picker__items"
			 (click)="onChangeColor($event)">
			<div *ngFor="let item of colors"
				 class="mc-color-picker__item"
				 [style.background]="'linear-gradient(315deg,' + item.color +' 58.49%, ' + item.color + '80 100%)'"
				 [attr.data-color]="item.color"
				 [attr.data-color-name]="item.name">
				<div class="mc-color-picker__item-color">{{ item.name }}</div>
			</div>
		</div>
	</ng-template>
</div>
