import { Injectable } from '@angular/core';
import { AbstractMesh, BoundingBoxGizmo, PointerDragBehavior, Vector3 } from '@babylonjs/core';
import { Logger } from '@babylonjs/core/Misc/logger';
import { PivotTools } from '@babylonjs/core/Misc/pivotTools';

@Injectable({
	providedIn: 'root'
})
export class GizmoService {

	constructor() {
	}

	updateScaleBoxDragBehavior(this: BoundingBoxGizmo, isImageVisualisation: boolean): void {
		// @ts-ignore
		const scaleBoxes = this._scaleBoxesParent.getChildMeshes();
		let scaleBoxIndex = 0;

		for (let i = 0; i < 3; i++) {
			for (let j = 0; j < 3; j++) {
				for (let k = 0; k < 3; k++) {
					const _loop_2 = (() => {
						// create box for relevant axis
						const zeroAxisCount = ((i === 1) ? 1 : 0) + ((j === 1) ? 1 : 0) + ((k === 1) ? 1 : 0);
						if (zeroAxisCount === 1 || zeroAxisCount === 3) {
							return 'continue';
						}

						const box = scaleBoxes[scaleBoxIndex++] as AbstractMesh;

						// Dragging logic
						const dragAxis = new Vector3(i - 1, j - 1, k - 1).normalize();
						const _dragBehavior = box.behaviors[0] as PointerDragBehavior;
						_dragBehavior.onDragObservable.clear();
						_dragBehavior.onDragObservable.add((event) => onDragObservableHandler.call(this, event));

						function onDragObservableHandler(event) {
							this.onScaleBoxDragObservable.notifyObservers({});
							if (this.attachedMesh) {
								const originalParent = this.attachedMesh.parent;
								if (originalParent && (originalParent.scaling && originalParent.scaling.isNonUniformWithinEpsilon(0.001))) {
									Logger.Warn('BoundingBoxGizmo controls are not supported on child meshes with non-uniform parent scaling');
									return;
								}

								if (isImageVisualisation) {
									this._anchorMesh.scaling = new Vector3(zeroAxisCount === 0 ? 1 : 2, 1, 1);
								}

								PivotTools._RemoveAndStorePivotPoint(this.attachedMesh);
								const relativeDragDistance = (event.dragDistance / this._boundingDimensions.length()) * this._anchorMesh.scaling.length();
								const deltaScale = new Vector3(relativeDragDistance, relativeDragDistance, 0);
								if (zeroAxisCount === 2) {
									// scale on 1 axis when using the anchor box in the face middle
									deltaScale.x *= Math.abs(dragAxis.x);
									deltaScale.y *= Math.abs(dragAxis.y);
									deltaScale.z *= Math.abs(dragAxis.z);
								}
								deltaScale.scaleInPlace(this._scaleDragSpeed);
								this.updateBoundingBox();
								if (this.scalePivot) {
									this.attachedMesh.getWorldMatrix().getRotationMatrixToRef(this._tmpRotationMatrix);
									// Move anchor to desired pivot point (Bottom left corner + dimension/2)
									this._boundingDimensions.scaleToRef(0.5, this._tmpVector);
									Vector3.TransformCoordinatesToRef(this._tmpVector, this._tmpRotationMatrix, this._tmpVector);
									this._anchorMesh.position.subtractInPlace(this._tmpVector);
									this._boundingDimensions.multiplyToRef(this.scalePivot, this._tmpVector);
									Vector3.TransformCoordinatesToRef(this._tmpVector, this._tmpRotationMatrix, this._tmpVector);
									this._anchorMesh.position.addInPlace(this._tmpVector);
								} else {
									// Scale from the position of the opposite corner
									box.absolutePosition.subtractToRef(this._anchorMesh.position, this._tmpVector);
									this._anchorMesh.position.subtractInPlace(this._tmpVector);
								}
								this._anchorMesh.addChild(this.attachedMesh);
								this._anchorMesh.scaling.addInPlace(deltaScale);
								if (this._anchorMesh.scaling.x < 0 || this._anchorMesh.scaling.y < 0 || this._anchorMesh.scaling.z < 0) {
									this._anchorMesh.scaling.subtractInPlace(deltaScale);
								}
								this._anchorMesh.removeChild(this.attachedMesh);
								this.attachedMesh.setParent(originalParent);
								PivotTools._RestorePivotPoint(this.attachedMesh);
							}
							this._updateDummy();
						}
					})();
				}
			}
		}
	}
}
