import { Directive, ElementRef, Inject, Input, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ScrollbarComponent } from './scrollbar.component';

@Directive({
	selector: '[appDraggable]',
})
export class DraggableDirective {
	@Input()
	draggable: 'vertical';

	@Output()
	dragged = fromEvent<TypedMouseEvent<HTMLElement>>(this.elementRef.nativeElement, 'mousedown').pipe(
		switchMap((event) => {
			event.preventDefault();

			const clientRect = event.target.getBoundingClientRect();
			const offsetVertical = getOffsetVertical(event, clientRect);

			return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
				map((e) => this.getScrolled(e, offsetVertical), takeUntil(fromEvent(this.documentRef, 'mouseup'))),
			);
		}),
	);

	constructor(
		@Inject(ScrollbarComponent) private readonly scrollbar: ScrollbarComponent,
		@Inject(DOCUMENT) private readonly documentRef: Document,
		@Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>,
	) {}

	private getScrolled({ clientY }: MouseEvent, offsetVertical: number): number {
		const { offsetHeight } = this.elementRef.nativeElement;
		const { nativeElement } = this.scrollbar.elementRef;
		const { top, height } = nativeElement.getBoundingClientRect();

		const maxTop = nativeElement.scrollHeight - height;
		const scrolledTop = (clientY - top - offsetHeight * offsetVertical) / (height - offsetHeight);

		return maxTop * scrolledTop;
	}
}

export type TypedMouseEvent<T extends EventTarget> = MouseEvent & { target: T };

function getOffsetVertical({ clientY }: MouseEvent, { top, height }: ClientRect): number {
	return (clientY - top) / height;
}
