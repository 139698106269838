import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { STORAGE_NAMES } from '@root/app.config';

@Component({
	selector: 'app-size-disclaimer',
	templateUrl: './size-disclaimer.component.html',
	styleUrls: ['./size-disclaimer.component.scss', './size-disclaimer.responsive.scss']
})
export class SizeDisclaimerComponent implements OnInit, OnDestroy {
	getGizmoControlAction: Subscription;
	getViewType: Subscription;
	showDisclaimer = false;

	constructor(
		private shareService: ShareService,
		private sessionStorageService: SessionStorageService
	) { }

	ngOnInit(): void {
		this.getShowDisclaimerStatus();

		this.getGizmoControlAction = this.shareService.getGizmoControlAction.subscribe(res => {
			const disclaimerSessionStatus = this.sessionStorageService.getSession(STORAGE_NAMES.zip_size_disclaimer);
			
			if (!disclaimerSessionStatus) {
				this.showDisclaimer = res;
			}
		});

		this.getViewType = this.shareService.getViewType.subscribe(this.getShowDisclaimerStatus.bind(this));
	}

	getShowDisclaimerStatus(): void {
		const ivTypeSession = this.sessionStorageService.getSession(STORAGE_NAMES.zip_iv_type);

		setTimeout(() => this.showDisclaimer = ivTypeSession === 'project_sample', 2000);
	}

	ngOnDestroy() {
		this.getGizmoControlAction.unsubscribe();
		this.getViewType.unsubscribe();
	}
	
	onClose(): void {
		this.showDisclaimer = false;
		this.sessionStorageService.setSession(true, STORAGE_NAMES.zip_size_disclaimer);
	}
}
