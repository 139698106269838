import { Component, OnInit } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';

@Component({
	selector: 'app-control-model',
	templateUrl: './control-model.component.html',
	styleUrls: ['./control-model.component.scss']
})
export class ControlModelComponent implements OnInit {
	active: boolean;
	
	constructor(
		private engineService: EngineService,
	) {
	}
	
	ngOnInit(): void {
	}
	
	onControl() {
		this.active = !this.active;
		this.engineService.setGizmoControl(this.active);
	}
}
