import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { NetworkStatus } from '@root/app.interfaces';
import { ModalService } from '@core/services/modal/modal.service';

@Component({
	selector: 'app-error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.scss', './error-modal.responsive.scss']
})
export class ErrorModalComponent implements OnInit, OnDestroy {
	getNetworkStatus: Subscription;
	
	constructor(
		private router: Router,
		private shareService: ShareService,
		private modalService: ModalService,
		private dialogRef: MatDialogRef<ErrorModalComponent>,
		
		@Inject(MAT_DIALOG_DATA) public data: NetworkStatus
	) { }
	
	ngOnInit() {
		this.modalService.liftUpModal(this.dialogRef);
		this.getNetworkStatus = this.shareService.getNetworkStatus.subscribe(res => {
			if (res.status) {
				this.closeDialog();
			}
		});
	}
	
	ngOnDestroy() {
		this.getNetworkStatus.unsubscribe();
	}
	
	goHomeButton() {
		void this.router.navigateByUrl('/');
	}
	
	closeDialog(): void {
		this.dialogRef.close();
	}
}
