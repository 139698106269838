<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="close()"></div>
	<div class="mc-modal__title">{{ 'modals.' + modalData.page + '.title' | translate }}</div>
	<div class="mc-modal__subtitle" *ngIf="subtitle">{{ subtitle }}</div>

	<div class="mc-modal__buttons">
		<div class="mc-modal__button mc-modal__button--yellow" (click)="close(true)">
			{{ 'modals.' + modalData.page + '.yes' | translate }}
		</div>
		<div class="mc-modal__button" (click)="close()">
			{{ 'modals.' + modalData.page + '.no' | translate }}
		</div>
	</div>
</div>
