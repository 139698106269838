import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '@core/services/modal/modal.service';
import { QuizModalComponent } from '@shared/modals/quiz-modal/quiz-modal.component';
import { QuizCloseButton, TaskbarItem } from '@root/app.interfaces';
import { STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-taskbar',
  templateUrl: './taskbar.component.html',
  styleUrls: ['./taskbar.component.scss', './taskbar.responsive.scss']
})
export class TaskbarComponent implements OnInit, OnDestroy {
	@ViewChild('taskbarPanelBlock') taskbarPanelBlock: ElementRef<HTMLElement>;

	getQuizHandler: Subscription;
	getViewType: Subscription;
	getTaskbarPanel: Subscription;
	getToolBarEvent: Subscription;

	taskbarHasUpdate = false;
	taskbarContainer = false;
	taskbarPanel = true;
	taskbarControls = false;

	taskbarItems = [
		{
			title: this.translateService.instant('taskbar.items.control_panel.title'),
			id: 'control',
			active: false,
			status: null,
			button: {
				type: 'yellow',
				text: this.translateService.instant('taskbar.items.control_panel.button.show')
			}
		},
		{
			title: this.translateService.instant('taskbar.items.onboarding.title'),
			id: 'help',
			active: false,
			status: null,
			button: {
				type: 'white',
				text: this.translateService.instant('taskbar.items.onboarding.button.review')
			}
		}
	];

	constructor(
		private readonly translateService: TranslateService,
		private sessionStorageService: SessionStorageService,
		private matDialog: MatDialog,
		private location: Location,
		private modalService: ModalService,
		private shareService: ShareService
	) { }

	@HostListener('document:click', ['$event.target'])
	onClick(targetElement: HTMLElement): void {
		const isClickedInside = this.taskbarPanelBlock?.nativeElement.contains(targetElement);

		if (!isClickedInside) {
			this.taskbarContainer = isClickedInside;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		const currentItem = this.taskbarItems.find(item => item.id === 'control');

		this.taskbarControls = !this.taskbarControls;
		this.onControlPanelHandler(currentItem);
	}
	
	ngOnInit(): void {
		this.currentLinkHandler();
		this.getTaskbarPanel = this.shareService.getTaskbarPanel.subscribe(res => {
			this.taskbarPanel = res;

			if (this.taskbarPanel) { this.resetControlStatus('control'); }
		});
		this.getViewType = this.shareService.getViewType.subscribe(this.viewTypeChangeHandler.bind(this));

		this.getQuizHandler = this.shareService.getQuizStatus.subscribe((data: QuizCloseButton) => {
			if (!data.status) {
				this.taskbarHasUpdate = !data.status;

				const getQuiz = this.taskbarItems.find(item => item.id === 'help');
				getQuiz.status = !data.status;

				if (data.buttonType === 'next') {
					getQuiz.status = false;
					this.taskbarUpdatesHandler();
				}
			}
		});

		this.getToolBarEvent = this.shareService.getToolBarEvent.subscribe((status) => {
			const currentItem = this.taskbarItems.find(item => item.id === 'control');
			currentItem.active = status;
			this.taskbarControls = !status;

			this.onControlPanelHandler(currentItem, false);
		});
	}

	ngOnDestroy() {
		this.getQuizHandler.unsubscribe();
		this.getViewType.unsubscribe();
		this.getTaskbarPanel.unsubscribe();
		this.getToolBarEvent.unsubscribe();
	}

	currentLinkHandler(): void {
		const currentDomainLink = this.location.path().split('?');
		const currentLink = currentDomainLink[0].split('/')[1] || 'home';
		if (currentLink === 'overview') {
			this.taskbarPanel = false;
		} else if (!this.matDialog.getDialogById('quiz-modal')) {
			const getCurrentViewType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type);
			this.viewTypeChangeHandler(getCurrentViewType);
		}
	}

	onControlHandler(event: Event): void {
		const buttonElement = event.currentTarget as HTMLButtonElement;
		const currentItem = this.taskbarItems.find(item => item.id === buttonElement.id);

		currentItem.active = !currentItem.active;

		if (buttonElement.id === 'control') {
			this.onControlPanelHandler(currentItem);
		}

		if (buttonElement.id === 'help') {
			this.onOnboardingHandler(currentItem);
		}
	}

	resetControlStatus(id: string) {
		const currentItem = this.taskbarItems.find(item => item.id === id);

		if (currentItem.id === 'control') {
			this.taskbarControls = true;
			currentItem.active = false;
			this.onControlPanelHandler(currentItem);
		}
	}

	onControlPanelHandler(sectionObject: TaskbarItem, setControlsStatus: boolean = true): void {
		this.taskbarControls = !this.taskbarControls;

		if (setControlsStatus) {
			this.shareService.setTaskbarControls(this.taskbarControls);
		}

		if (this.taskbarControls) {
			this.taskbarHasUpdate = true;
		}

		sectionObject.status = this.taskbarControls;
		sectionObject.button.text = this.taskbarControls ?
			this.translateService.instant('taskbar.items.control_panel.button.hide') :
			this.translateService.instant('taskbar.items.control_panel.button.show');

		this.taskbarUpdatesHandler();
	}

	onOnboardingHandler(sectionObject: TaskbarItem): void {
		if (!this.matDialog.getDialogById('quiz-modal')) {
			this.matDialog.open(QuizModalComponent, this.modalService.getConfig(QuizModalComponent.name));

			sectionObject.status = false;
			this.taskbarUpdatesHandler();
		}
	}

	taskbarUpdatesHandler(): void {
		const panelHasUpdates = this.taskbarItems.find(item => item.status);

		this.taskbarHasUpdate = panelHasUpdates?.status || false;
	}

	viewTypeChangeHandler(type): void {
		const quizReviewedStatus = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_quiz_reviewed_status);
		const isDesignType = type === VIEW_TYPES.design;

		const reviewedStatus = isDesignType ? quizReviewedStatus?.dyb : quizReviewedStatus?.iv;

		const getQuiz = this.taskbarItems.find(item => item.id === 'help');
		getQuiz.status = !reviewedStatus;

		this.taskbarUpdatesHandler();
	}

	onTaskbarHandler(event: Event) {
		event.preventDefault();

		this.taskbarContainer = !this.taskbarContainer;
	}
}
