import { Injectable, OnDestroy } from '@angular/core';
import { Countries, SELECTORS, STORAGE_NAMES } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BlindTypeModalComponent } from '@shared/modals/blind-type-modal/blind-type-modal.component';
import { SelectBackgroundModalComponent } from '@shared/modals/select-background-modal/select-background-modal.component';
import { Observable, Subscription } from 'rxjs';
import { ModalService } from '@core/services/modal/modal.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';

import * as _ from 'lodash';

declare const $: any;

@Injectable({
	providedIn: 'root',
})
export class SetupPageService implements OnDestroy {
	getEmbeddedStatus: Subscription;
	isEmbedded = false;

	constructor(
		private sessionStorageService: SessionStorageService,
		private shareDataService: ShareService,
		private modalService: ModalService,
		private serverDataService: ServerDataService,
		public dialog: MatDialog,
	) {
		this.getEmbeddedStatus = this.shareDataService.getEmbeddedStatus.subscribe((res: boolean) => {
			this.isEmbedded = res;
		});
	}

	ngOnDestroy() {
		this.getEmbeddedStatus.unsubscribe();
	}

	getScreenOrientation(): string {
		return window.orientation === 0 || window.orientation === 180 ? 'portrait' : 'landscape';
	}

	getCountryCode(): any {
		return this.sessionStorageService.getSession(STORAGE_NAMES.zip_country_code);
	}

	getProductType(): string {
		const getBlindConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config) || {};

		const productTypes = {
			outdoor: Object.hasOwn(getBlindConfig, 'outdoor'),
			interior: Object.hasOwn(getBlindConfig, 'interior'),
		};

		const configProductType = productTypes.outdoor && productTypes.interior ? 'all' : productTypes.interior ? 'interior' : 'outdoor';
		const embeddedRetailerType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer)?.product;

		return embeddedRetailerType || configProductType;
	}

	validateCountryCodeHandler(code: string): boolean {
		return !_.isEmpty(Countries.filter((item) => item.code === code));
	}

	/*
	 * The resetOnBeforeUnloadHandler() method
	 * Prevents standard modal windows from appearing in browsers by resetting the onbeforeunload event.
	 * */

	resetOnBeforeUnloadHandler(): void {
		window.onbeforeunload = () => {};
	}

	parseLocalLinkHandler(): void {
		const getCountryCode = this.getCountryCode();
		let link = '';

		for (const item of Countries) {
			if (item.code === getCountryCode) {
				link = item.link;
			}
		}

		link = this.isEmbedded ? '/' : link || Countries.filter((item) => item.code === 'au')[0].link;
		window.open(link, '_self');
	}

	sendCountryCodeForRender(code: string, status: boolean): void {
		if (status || this.getCountryCode()) {
			const country = this.getCountryCode() === code ? code : status ? code : this.getCountryCode();

			this.shareDataService.setCountry(country);
			this.sessionStorageService.setSession(country, STORAGE_NAMES.zip_country_code);
		} else {
			this.setCountryCode();
		}
	}

	sendBlindTypeForRender(status?: boolean, observable?: Observable<string>): MatDialogRef<BlindTypeModalComponent> | null {
		const dialogExist = this.dialog.getDialogById('blind-type');

		if (!dialogExist) {
			const dialogData = this.dialog.open(BlindTypeModalComponent, {
				id: 'blind-type',
				maxWidth: '800px',
				height: 'auto',
				minHeight: '130px',
				data: { status, observable },
				disableClose: true,
				backdropClass: status ? 'mc-blind-modal__bg' : '',
				panelClass: status
					? ['mc-blind-modal__dialog', this.mobileAndTabletCheck() ? 'mc-country-modal__bg' : '']
					: 'mc-blind-modal__internal',
				closeOnNavigation: false,
			});

			dialogData.afterOpened().subscribe(() => setTimeout(() => $(SELECTORS.overlay_container)[0].scrollTo(0, 0), 0));
			return dialogData;
		}
	}

	setWelcomeModal(): MatDialogRef<SelectBackgroundModalComponent> | null {
		const dialogExist = this.dialog.getDialogById('select-background');

		if (!dialogExist) {
			const dialogData = this.dialog.open(SelectBackgroundModalComponent, this.modalService.getConfig(SelectBackgroundModalComponent.name));

			dialogData.afterOpened().subscribe(() => setTimeout(() => $(SELECTORS.overlay_container)[0].scrollTo(0, 0), 0));
			return dialogData;
		}
	}

	mobileAndTabletCheck(): boolean {
		let check = false;
		((a) => {
			if (
				/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
					a,
				) ||
				// @ts-ignore
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
					a.substr(0, 4),
				)
			) {
				check = true;
			}
		})(navigator.userAgent || navigator.vendor);
		return check || 'ontouchstart' in window;
	}

	setCountryCode(): void {
		this.serverDataService.getCountryCodeFromAPI().subscribe((data) => {
			const code = Countries.filter((country) => country.code === data.country_code.toLocaleLowerCase())[0]?.code || 'au';
			this.shareDataService.setCountry(code);
			this.sessionStorageService.setSession(code, STORAGE_NAMES.zip_country_code);
		});
	}
}
