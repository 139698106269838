import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { CONFIG, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';

import * as _ from 'lodash';
import { ShutterValues } from '@root/app.interfaces';

@Component({
	selector: 'app-shutter-control',
	templateUrl: './shutter-control.component.html',
	styleUrls: ['./shutter-control.component.scss', './shutter-control.responsive.scss']
})
export class ShutterControlComponent implements OnInit, OnDestroy {
	getViewType: Subscription;
	getAccordingType: Subscription;
	getModelLoaded: Subscription;
	getSessionBlindsData: Subscription;
	getShutterControlValue: Subscription;

	isMobile: boolean;

	value = 1;
	step = 0.001;
	status: number;
	hasBlinds: boolean = !_.isEmpty(this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data));
	isImageVisualisation = false;
	shutterControlStatus = false;
	viewType: string = VIEW_TYPES.design;
	shutterValues = {};

	IVShutterStatus = true;
	IVDefaultValue: number;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.shutterControlStatus = window.innerWidth >= CONFIG.breakpoints['tablet-landscape'];
	}

	constructor(
		private engineService: EngineService,
		private shareService: ShareService,
		private setupPageService: SetupPageService,
		private sessionStorageService: SessionStorageService
	) {}

	ngOnInit(): void {
		this.shutterHandler();
		this.onResize();
		this.isMobile = this.setupPageService.mobileAndTabletCheck();

		if (this.IVShutterStatus) {
			this.IVDefaultValue = 0.5;
			this.engineService.shutter.IVDefaultValue = this.IVDefaultValue;
		}
	}

	ngOnDestroy() {
		this.getAccordingType.unsubscribe();
		this.getModelLoaded.unsubscribe();
		this.getSessionBlindsData.unsubscribe();
		this.getViewType.unsubscribe();
		this.getShutterControlValue.unsubscribe();
	}

	onShutterChange(data): void {
		this.engineService.setTop(this.value - data.value);
	}

	shutterHandler(): void {
		this.getModelLoaded = this.shareService.getSceneCreated.subscribe(res => {
			this.hasBlinds = !_.isEmpty(this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data));
			this.closeShutterOnIVHandler();
		});

		this.getAccordingType = this.shareService.getAccordionType.subscribe(this.closeShutterOnIVHandler.bind(this));
		this.getSessionBlindsData = this.shareService.getSessionBlindsData.subscribe(res => this.hasBlinds = res);
		this.getShutterControlValue = this.engineService.getShutterControlValue().subscribe((data: ShutterValues) => {
			this.status = this.value - data.value;
			this.engineService.setTop(data.value);
			this.shutterValues[data.id] = data.value;
		});

		this.viewTypeHandler();
	}

	closeShutter(): void {
		this.status = this.viewType === VIEW_TYPES.image_visualisation && this.IVShutterStatus ? 0.5 : 0;
		this.onShutterChange({ value: this.status });
	}

	onToggleShutterControl() {
		this.shutterControlStatus = !this.shutterControlStatus;
	}

	viewTypeHandler(): void {
		this.getViewType = this.shareService.getViewType.subscribe(type => {
			this.isImageVisualisation = type === VIEW_TYPES.image_visualisation;
			
			if (type !== this.viewType) {
				if (this.isImageVisualisation && !this.IVShutterStatus || !this.isImageVisualisation) {
					this.closeShutter();
				}

				this.onResize();
			}

			this.viewType = type;
		});
	}

	closeShutterOnIVHandler(): void {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);

		if (+this.engineService.selectedGizmoId === currentBlindId && !this.shutterValues?.[currentBlindId] || !this.isImageVisualisation) {
			this.closeShutter();
		}
	}

}
