import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { ModalService } from '@core/services/modal/modal.service';
import { DEVICES, STORAGE_NAMES } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { BackgroundConfig } from '@root/app.interfaces';

@Component({
	selector: 'app-another-device-modal',
	templateUrl: './another-device-modal.component.html',
	styleUrls: ['./another-device-modal.component.scss', './another-device-modal.responsive.scss'],
})
export class AnotherDeviceModalComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<AnotherDeviceModalComponent>,
		private sessionStorageService: SessionStorageService,
		private saveSessionBlind: SaveSessionBlindsService,
		private countryCodeService: SetupPageService,
		private modalService: ModalService,
	) {}

	ngOnInit() {
		this.modalService.liftUpModal(this.dialogRef);
	}

	closeDialog(): void {
		this.dialogRef.close(false);
	}

	onContinue(): void {
		this.updateIVSceneSettings();

		this.saveSessionBlind.PutStorageDataToServerPromise().then(() => {
			this.dialogRef.close(true);
		});
	}

	updateIVSceneSettings(): void {
		const deleteBlindSettings = (ivBackgroundSettings: BackgroundConfig) => {
			if (!ivBackgroundSettings) {
				return undefined;
			}

			delete ivBackgroundSettings.settings;
			delete ivBackgroundSettings.shutterValues;
			delete ivBackgroundSettings.sampleProjectBlinds;

			return ivBackgroundSettings;
		};

		const ivCurrentBackground = deleteBlindSettings(
			this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background),
		);
		if (ivCurrentBackground) {
			this.sessionStorageService.setBlindData(ivCurrentBackground, STORAGE_NAMES.zip_image_visualisation_background);
		}

		const ivUploadedBackground = deleteBlindSettings(
			this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_uploaded_image_visualisation_background),
		);
		if (ivUploadedBackground) {
			this.sessionStorageService.setBlindData(ivUploadedBackground, STORAGE_NAMES.zip_uploaded_image_visualisation_background);
		}

		const storageConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
		const ivSceneSettings = storageConfig.iv_scene_settings;
		ivSceneSettings.device = this.countryCodeService.mobileAndTabletCheck() ? DEVICES.mobile : DEVICES.desktop;
		ivSceneSettings.sample = deleteBlindSettings(ivSceneSettings.sample);
		ivSceneSettings.upload = deleteBlindSettings(ivSceneSettings.upload);
		storageConfig.iv_scene_settings = ivSceneSettings;
		this.sessionStorageService.setBlindData(storageConfig, STORAGE_NAMES.zip_blind_config);
	}
}
