import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@core/pipes/pipes.module';

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		PipesModule
	]
})
export class CoreModule {
}
