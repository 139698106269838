<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="close()"></div>
	<div class="mc-modal__icon"></div>
	<div class="mc-modal__title">
		{{ 'modals.delete_blind.title' | translate }}
	</div>
	<div class="mc-modal__buttons">
		<div class="mc-modal__button" (click)="delete()">
			{{ 'modals.delete_blind.buttons.yes' | translate }}
		</div>
		<div class="mc-modal__button mc-modal__button--yellow" (click)="close()">
			{{ 'modals.delete_blind.buttons.no' | translate }}
		</div>
	</div>
</div>
