<div class="mc-select-background-modal-container internal">
	<div class="mc-select-background-modal__title" *ngIf="!getBlindData" [innerHTML]="'modals.select_background.title' | translate"></div>
	<div class="mc-select-background-modal">
		<ng-container *ngIf="!getBlindData && getProductType === 'all'">
			<div class="mc-select-background-modal__subtitle">
				{{ 'modals.select_background.subtitle_type' | translate }}
			</div>

			<div class="mc-select-background-modal__buttons product" [ngClass]="{ 'mobile-view': isMobile }">
				<button
					(click)="onSelectProductType('outdoor')"
					[ngClass]="{ active: selectedProductType === 'outdoor' }"
					class="mc-select-background-modal-button outdoor"
				>
					{{ 'modals.select_background.buttons.outdoor' | translate }}
				</button>
				<button
					(click)="onSelectProductType('interior')"
					[ngClass]="{ active: selectedProductType === 'interior' }"
					class="mc-select-background-modal-button interior"
				>
					{{ 'modals.select_background.buttons.interior' | translate }}
				</button>
			</div>
		</ng-container>

		<div class="mc-select-background-modal__subtitle">
			{{ 'modals.select_background.subtitle_background' | translate }}
		</div>
		<div class="mc-select-background-modal__buttons" [ngClass]="{ 'mobile-view': isMobile }">
			<button
				(click)="onSelectType('upload')"
				[ngClass]="{ active: selectedBackgroundType === 'upload' }"
				class="mc-select-background-modal-button upload"
			>
				{{ 'modals.select_background.buttons.upload' | translate }}
			</button>

			<button class="mc-select-background-modal-button live" *ngIf="isMobile" (click)="openLive()">
				{{ 'modals.select_background.buttons.live_background' | translate }}
			</button>

			<button
				(click)="onSelectType('project_sample')"
				[ngClass]="{ active: selectedBackgroundType === 'project_sample' }"
				class="mc-select-background-modal-button use-sample"
			>
				{{ 'modals.select_background.buttons.use_sample' | translate }}
			</button>

			<app-augmented-reality
				class="ar-button-container"
				*ngIf="isMobile"
				[selectedProductType]="selectedProductType"
				(selectProductType)="onBlindTypeSelect($event)"
			></app-augmented-reality>
		</div>
		<form #ngForm="ngForm" #formElement (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="selectedBackgroundType">
			<div *ngIf="selectedBackgroundType === 'project_sample'" class="mc-select-background-modal__projects">
				<div
					class="mc-select-background-modal__projects-samples outdoor"
					*ngIf="getProductType === 'outdoor' || getProductType === 'all' || isImageVisualisation"
				>
					<div class="mc-select-background-modal__projects-samples-title" *ngIf="getProductType === 'all' || isImageVisualisation">
						{{ 'modals.select_background.project_samples.outdoor' | translate }}
					</div>
					<ng-container *ngTemplateOutlet="carouselTemplate; context: { type: 'outdoor', samples: projectSamples['outdoor'] }">
					</ng-container>
				</div>
				<div
					class="mc-select-background-modal__projects-samples interior"
					*ngIf="getProductType === 'interior' || getProductType === 'all' || isImageVisualisation"
				>
					<div class="mc-select-background-modal__projects-samples-title" *ngIf="getProductType === 'all' || isImageVisualisation">
						{{ 'modals.select_background.project_samples.interior' | translate }}
					</div>
					<ng-container *ngTemplateOutlet="carouselTemplate; context: { type: 'interior', samples: projectSamples['interior'] }">
					</ng-container>
				</div>
				<div class="mc-select-background-modal__fixed">
					<button
						class="mc-select-background-modal-submit-button"
						[class]="{ loading: isLoading }"
						[disabled]="!form.value.photo_base64 || isLoading"
						type="submit"
					>
						{{ 'modals.select_background.project_samples.button' | translate }}
					</button>
				</div>
			</div>
			<div *ngIf="selectedBackgroundType === 'upload'" class="mc-select-background-modal__upload">
				<div *ngIf="form.value.photo_base64" class="mc-select-background-modal__subtitle">
					{{ 'modals.select_background.upload.upload_title' | translate }}
				</div>
				<div
					(dragleave)="onDragEvent($event)"
					(dragover)="onDragEvent($event)"
					(drop)="onDropFile($event)"
					class="mc-select-background-modal__upload-drag-area"
				>
					<div class="mc-select-background-modal__subtitle">
						{{ 'modals.select_background.upload.title' | translate }}
					</div>
					<span
						*ngIf="maxSizeError"
						[innerHTML]="'modals.select_background.upload.errors.max_size' | translate"
						class="mc-select-background-modal__upload-error"
					></span>
					<span
						*ngIf="fileTypeError"
						[innerHTML]="'modals.select_background.upload.errors.valid_type' | translate"
						class="mc-select-background-modal__upload-error"
					></span>
					<label class="mc-select-background-modal-submit-button" for="upload_photo">
						{{ 'modals.select_background.upload.upload_button' | translate }}
					</label>
					<span [innerHTML]="'modals.select_background.upload.prompt' | translate" class="mc-select-background-modal__upload-prompt"></span>
					<input
						(change)="onSelectFile($event)"
						accept="image/*"
						class="th-auth__input-photo"
						formControlName="photo"
						hidden
						id="upload_photo"
						type="file"
					/>

					<div (click)="onDeleteFile()" *ngIf="form.value.photo_base64" class="mc-select-background-modal__upload-delete"></div>
					<img *ngIf="form.value.photo_base64" [src]="form.value.photo_base64" alt="" class="mc-select-background-modal__upload-image" />
				</div>
				<div *ngIf="!form.value.photo_base64" class="mc-select-background-modal__text">
					{{ 'modals.select_background.upload.text' | translate }}
				</div>
				<button
					class="mc-select-background-modal-submit-button"
					[class]="{ loading: isLoading }"
					[disabled]="!form.value.photo_base64 || isLoading"
					type="submit"
				>
					{{ 'modals.select_background.upload.use_button' | translate }}
				</button>
			</div>
		</form>
	</div>
	<div *ngIf="getBlindData" (click)="onClose()" class="mc-select-background-modal-close"></div>
</div>

<ng-template #carouselTemplate let-type="type" let-samples="samples">
	<ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" class="carousel mc-carousel-samples">
		<ng-container *ngFor="let sample of samples; let i = index; trackBy: trackByIndex">
			<div class="slide" ngxSlickItem>
				<div
					(click)="onSelectProjectSample($event, sample, type, i)"
					[ngClass]="{ active: sampleType === type && sampleIndex === i }"
					class="mc-select-background-modal__projects-samples-images"
				>
					<img *ngIf="sample.includes('preview')" alt="" src="{{ sample }}" />
				</div>
			</div>
		</ng-container>
	</ngx-slick-carousel>
</ng-template>
