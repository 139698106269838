import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SELECTORS } from '@root/app.config';
import { PassDataService } from '@core/services/pass-data/pass-data.service';

@Component({
	selector: 'app-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit, OnDestroy {
	@Input() colors;
	@Input() data;
	@Input() placement;
	@Output() setColorData = new EventEmitter();
	@ViewChild('colorPickerPopover') colorPickerPopover: NgbPopover;

	isMobile = this.passDataService.isMobileOrTablet;
	scrollSelector = this.isMobile ? SELECTORS.blind_list : SELECTORS.simplebar_content_wrapper;

	constructor(private passDataService: PassDataService) {}

	ngOnInit(): void {
		document.querySelector(this.scrollSelector)?.addEventListener('scroll', this.onDocumentScroll.bind(this));
	}

	ngOnDestroy(): void {
		document.querySelector(this.scrollSelector)?.removeEventListener('scroll', this.onDocumentScroll);
	}

	onChangeColor(event): void {
		const color = event.target.dataset.color;
		const name = event.target.dataset.colorName;

		if (color && name) {
			this.data.color = color;
			this.data.color_name = name;
			this.data.event = event;
			this.setColorData.emit(this.data);
		}

		if (this.isMobile) {
			this.colorPickerPopover.close();
		}
	}

	onDocumentScroll(event: Event): void {
		const popoverParent = this.colorPickerPopover['_nativeElement'];
		const toolBar = event.target as HTMLElement;
		const popoverParentRect = popoverParent.getBoundingClientRect();
		const toolBarRect = toolBar.getBoundingClientRect();

		if (
			(popoverParentRect.top && popoverParentRect.top < toolBarRect.top) ||
			(popoverParentRect.bottom && popoverParentRect.bottom > toolBarRect.bottom)
		) {
			this.colorPickerPopover.close();
		}
	}
}
