import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { DeleteModalComponent } from '@shared/modals/delete-modal/delete-modal.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CONFIG, BREAKPOINTS, STORAGE_NAMES, SELECTORS, CLASSES, VIEW_TYPES } from '@root/app.config';
import { Breakpoints, Retailer } from '@root/app.interfaces';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { RetailerInfoModalComponent } from '@shared/modals/retailer-info-modal/retailer-info-modal.component';
import { SaveWorkEmailModalComponent } from '@shared/modals/save-work-email-modal/save-work-email-modal.component';
import { ModalService } from '@core/services/modal/modal.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';

@Component({
	selector: 'app-summary-table',
	templateUrl: './summary-table.component.html',
	styleUrls: [
		'./summary-table.component.scss',
		'./summary-table.responsive.scss',
		'../overview-page/overview-page.component.scss',
		'../overview-page/overview-page.responsive.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class SummaryTableComponent implements OnInit, OnDestroy {
	@ViewChild('requestBlockButtons')
	requestBlockButtons: ElementRef<HTMLDivElement>;
	@ViewChild('mobileRequestBlockButtons')
	mobileRequestBlockButtons: ElementRef<HTMLDivElement>;

	getEmbeddedRetailer: Subscription;
	getSessionBLindData: Subscription;
	getActivatedRouteRules: Subscription;
	getScreenshotLoadedOnServer: Subscription;

	blindsData = [];
	filteredBlindsData = [];
	blindOptionAvailability = {
		frame_color: false,
		frame_style: false,
		frame_bar: false,
		material: false,
		size: false,
		operation: false,
		mounting: false,
	};
	hasExtraSizeBlind = false;
	hasBlindsData = false;
	downloading = false;
	isScreenshotLoadedOnServer: boolean;
	saving = false;
	type = 'outdoor';
	types: string[];
	breakpoints: Breakpoints = BREAKPOINTS;
	isMobile: boolean;
	isProd = this.passDataService.isProd;

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		infinite: false,
		variableWidth: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: CONFIG.breakpoints['tablet-landscape'],
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};

	embeddedRetailer: Retailer = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer);
	storageText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);
	sessionText = _.isEmpty(this.storageText) ? 'Some text' : this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);

	@HostListener('window:scroll') onScroll() {
		const element = this.requestBlockButtons?.nativeElement.getBoundingClientRect().top || 0;
		const mobileElement = this.mobileRequestBlockButtons?.nativeElement.getBoundingClientRect().top || 0;
		const summaryButtonsTop = mobileElement || element;
		const viewPortButtons = window.innerHeight >= summaryButtonsTop + 64;

		document.querySelector(SELECTORS.overview_bottom_panel).classList.toggle('hidden', viewPortButtons);
		document.querySelector(SELECTORS.top_button).classList.toggle('offsetted', !viewPortButtons);
	}

	constructor(
		private serverDataService: ServerDataService,
		private sessionStorageService: SessionStorageService,
		private saveSessionBlind: SaveSessionBlindsService,
		private countryCodeService: SetupPageService,
		private engineService: EngineService,
		private route: ActivatedRoute,
		private router: Router,
		private shareService: ShareService,
		private changeDetection: ChangeDetectorRef,
		private modalService: ModalService,
		private passDataService: PassDataService,
		public dialog: MatDialog,
	) {}

	ngOnInit(): void {
		this.sceneHandler();
		this.isMobile = this.countryCodeService.mobileAndTabletCheck();
		this.getActivatedRouteRules = this.route.data.subscribe(() => {
			this.getSessionStorageData();
		});

		if (!this.hasBlindsData) {
			this.getSessionBLindData = this.shareService.getSessionBlindsData.subscribe(() => {
				this.getSessionDefaultValue();
				this.getSessionStorageData();
			});
		}

		this.isScreenshotLoadedOnServer = !_.isUndefined(this.passDataService.isScreenShotLoadedOnServer)
			? this.passDataService.isScreenShotLoadedOnServer
			: true;
		this.getScreenshotLoadedOnServer = this.shareService.getScreenShotLoadedOnServer.subscribe(
			(status: boolean) => (this.isScreenshotLoadedOnServer = status),
		);
		this.getEmbeddedRetailer = this.shareService.getEmbeddedRetailer.subscribe(() => {
			this.embeddedRetailer = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer);
		});
	}

	ngOnDestroy(): void {
		if (this.getSessionBLindData) {
			this.getSessionBLindData.unsubscribe();
		}
		if (this.getActivatedRouteRules) {
			this.getActivatedRouteRules.unsubscribe();
		}

		this.getScreenshotLoadedOnServer.unsubscribe();
		this.getEmbeddedRetailer.unsubscribe();
	}

	getSessionStorageData(): void {
		const storageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);

		if (!_.isEmpty(storageData)) {
			this.hasBlindsData = true;
			this.blindsData = [];
			for (const blind of storageData) {
				if (blind.has_name) {
					this.blindsData.push(blind);
				}
			}

			this.getBlindsTypes();
			this.checkOptionsAvailability();
		}

		this.changeDetection.markForCheck();
	}

	setBlindsTableData(): void {
		if (this.embeddedRetailer) {
			document.querySelector(SELECTORS.request_button).classList.add(CLASSES.loading);
		}

		this.shareService.setRetailerInfo(true);
	}

	checkOptionsAvailability(): void {
		const frameColor = 'frame_color';
		const frameStyle = 'frame_style';
		const frameBar = 'frame_bar';
		const material = 'material';
		const size = 'size';
		const operation = 'operation';
		const mounting = 'mounting';

		for (const option of this.blindsData) {
			if (!_.isEmpty(option.setup.frames)) {
				this.blindOptionAvailability[frameColor] = !this.blindOptionAvailability[frameColor]
					? !_.isEmpty(option.setup.frames.frame_color)
					: this.blindOptionAvailability[frameColor];
				this.blindOptionAvailability[frameStyle] = !this.blindOptionAvailability[frameStyle]
					? !_.isEmpty(option.setup.frames.top_style)
					: this.blindOptionAvailability[frameStyle];
				this.blindOptionAvailability[frameBar] = !this.blindOptionAvailability[frameBar]
					? !_.isEmpty(option.setup.frames.bottom_bar)
					: this.blindOptionAvailability[frameBar];
			}

			this.blindOptionAvailability[material] = !this.blindOptionAvailability[material]
				? !_.isEmpty(option.setup.material)
				: this.blindOptionAvailability[material];
			this.blindOptionAvailability[size] = !this.blindOptionAvailability[size]
				? !_.isEmpty(option.setup.size)
				: this.blindOptionAvailability[size];
			this.blindOptionAvailability[operation] = !this.blindOptionAvailability[operation]
				? !_.isEmpty(option.setup.operation)
				: this.blindOptionAvailability[operation];
			this.blindOptionAvailability[mounting] = !this.blindOptionAvailability[mounting]
				? !_.isEmpty(option.setup.mounting)
				: this.blindOptionAvailability[mounting];
		}
	}

	putDownloadData(): void {
		this.downloading = true;
		this.saveSessionBlind.putDownloadDataPromise().then(() => {
			this.downloading = false;
			this.changeDetection.markForCheck();
		});
	}

	putSaveBlinds(): void {
		this.modalService.openResponsiveDialog({
			...{ component: SaveWorkEmailModalComponent },
			...this.modalService.getConfig(SaveWorkEmailModalComponent.name),
		});
	}

	getSessionDefaultValue(): void {
		const sessionStorageText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);

		if (!_.isEmpty(sessionStorageText)) {
			this.sessionText = sessionStorageText;
		}
	}

	onSelectFilter(type: string): void {
		this.type = type;

		this.filteredBlindsData = this.blindsData.filter((blind) => blind.type === this.type || this.type === 'all');
		this.hasExtraSizeBlind = this.filteredBlindsData.some((blind) => blind.setup.size.extra_size);
	}

	getBlindsTypes(): void {
		this.types = [...new Set(this.blindsData.map((blind) => blind.type))].sort().reverse();

		this.onSelectFilter(this.types[0]);
	}

	trackByIndex(index: number): number {
		return index;
	}

	onDeleteBlind(id: number): void {
		const dialogRef = this.modalService.openResponsiveDialog({
			component: DeleteModalComponent,
			width: '100%',
			maxWidth: '680px',
			height: 'auto',
			minHeight: '200px',
		});

		dialogRef.afterClosed().subscribe((status) => {
			if (status) {
				const sessionSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
				this.getSessionStorageData();

				const deletedBlindIndex = this.blindsData.findIndex((x) => x.blind_id === id);
				if (deletedBlindIndex < this.blindsData.length - 1) {
					this.blindsData.forEach((x, i) => (x.name = i > deletedBlindIndex && x.name === `Blind ${i + 1}` ? `Blind ${i}` : x.name));
				}

				this.blindsData.splice(deletedBlindIndex, 1);
				this.sessionStorageService.setBlindData(this.blindsData, STORAGE_NAMES.zip_blind_data);
				this.getBlindsTypes();

				if (sessionSaved) {
					this.hasBlindsData = !_.isEmpty(this.blindsData);

					this.saveSessionBlind.PutStorageDataToServerPromise().then(() => {
						this.onDeleteLastBlind();
					});
				} else {
					this.onDeleteLastBlind();
				}
			}
		});
	}

	onDeleteLastBlind() {
		if (_.isEmpty(this.blindsData)) {
			window.scrollTo(0, 0);
			void this.router.navigate(['/']);

			this.sessionStorageService.removeData(STORAGE_NAMES.zip_image_visualisation_status);
			this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_blind_data);
			this.countryCodeService.setWelcomeModal();
			this.shareService.setBlindEmpty(true);
		}
	}

	onAddBlindItem() {
		this.router.navigateByUrl('home').then(() => {
			this.engineService.setBackgroundImage(null);
			const accordionHandler = this.shareService.getAccordionType.subscribe(() => {
				this.shareService.setAddBlind(false);
				accordionHandler.unsubscribe();
			});
		});
	}

	onOpenRetailerInfoModal() {
		this.modalService.openResponsiveDialog({
			component: RetailerInfoModalComponent,
			width: '100%',
			maxWidth: '280px',
			panelClass: '',
		});
	}

	onEditBlind(blind: any): void {
		this.sessionStorageService.setBlindData(blind.blind_id, STORAGE_NAMES.zip_current_blind_id);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		this.sessionStorageService.setSession(blind.type, STORAGE_NAMES.zip_blind_type);
		this.imageVisualisationStatusHandler(false);
		this.toHomePage();
	}

	onImageVisualisationBlind(blind: any): void {
		this.sessionStorageService.setBlindData(blind.blind_id, STORAGE_NAMES.zip_current_blind_id);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		this.sessionStorageService.setSession(blind.type, STORAGE_NAMES.zip_blind_type);
		this.imageVisualisationStatusHandler(true);
		this.toHomePage();
	}

	toHomePage(): void {
		this.router.navigateByUrl('home').then(() => {
			this.engineService.setBackgroundImage(null);
		});
	}

	onPopover(popover: NgbPopover, button: HTMLElement): void {
		button.classList.toggle('active', popover.isOpen());
	}

	sceneHandler(): void {
		const getViewType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_view_type);
		this.imageVisualisationStatusHandler(getViewType === VIEW_TYPES.image_visualisation || !getViewType);

		this.shareService.setTaskbarPanel(false);
		this.engineService.isModelCreated = false;
	}

	imageVisualisationStatusHandler(status: boolean): void {
		if (status) {
			this.sessionStorageService.setSession(true, STORAGE_NAMES.zip_image_visualisation_status);
		} else {
			this.sessionStorageService.removeData(STORAGE_NAMES.zip_image_visualisation_status);
		}
	}
}
