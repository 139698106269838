import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SPRING_BALANCE, REVERSE_HANDLE, STORAGE_NAMES, SELECTORS, VIEW_TYPES } from '../../app.config';
import { EngineService } from '@core/services/engine/engine.service';
import { SimpleChanges } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

declare const $: any;

@Component({
	selector: 'app-operation-change',
	templateUrl: './operation-change.component.html',
	styleUrls: ['./operation-change.component.scss', './operation-change.responsive.scss'],
})
export class OperationChangeComponent implements OnInit, OnDestroy, OnChanges {
	@Input() blindId;
	@Output() operationData = new EventEmitter();

	getBlindSelect: Subscription;
	serverOperationData;
	storageData = {
		operation: {
			id: '',
			optional: [],
			name: '',
		},
	};
	defaultId;
	blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
	sessionConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
	sessionText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);
	isDesignType: boolean = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;

	selectors = {
		list: '.mc-operation__list',
		optionList: '.mc-operation__optional',
		input: '.mc-operation__input',
	};
	random = Math.floor(10000 + Math.random() * 1000);

	constructor(
		private sessionStorageService: SessionStorageService,
		private shareDataService: ShareService,
		private saveSessionBlind: SaveSessionBlindsService,
		private screenShotService: ScreenshotService,
		private engineService: EngineService,
		private changeDetection: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.serverOperationData = this.sessionConfig?.[this.blindType].frame.operation;

		this.getStorageOperation();
		this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(this.setBlindSelectId.bind(this));
	}

	ngOnChanges(changes: SimpleChanges): void {
		const getCurrentBLindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);

		if (changes.blindId.currentValue === getCurrentBLindId) {
			setTimeout(this.setDefaultValue.bind(this));
		}
	}

	ngOnDestroy(): void {
		this.getBlindSelect.unsubscribe();
	}

	getStorageOperation(): void {
		const materialStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data);
		if (materialStorage) {
			for (const item of materialStorage) {
				if (item.setup && item.setup.operation) {
					const optionData = this.serverOperationData.find((x) => x.id === item.setup.operation.id);

					Object.assign(this.storageData.operation, item.setup.operation);
					this.storageData.operation.optional = [...item.setup.operation.optional];
					optionData.checked = true;

					if (!_.isEmpty(item.setup.operation.optional)) {
						for (const option of item.setup.operation.optional) {
							const optional = optionData.optional.find((y) => y.id === option.id);

							if (optional) {
								optional.checked = true;
							}
						}
					}

					this.modelManagingHandler(optionData.id, 'storage');
				}
			}
		}
	}

	onSubmitRadioOptions(event): void {
		const currentOption = $(event.currentTarget);
		const checkboxes = $(this.selectors.optionList).find(this.selectors.input);
		if (!currentOption.prev().is(':checked')) {
			$(this.selectors.list).removeClass('checked');
			for (const checkbox of checkboxes) {
				checkbox.checked = false;
			}

			currentOption.parents(this.selectors.list).addClass('checked');
			const optionID = currentOption.attr('id');

			this.modelManagingHandler(optionID, event.type);
		}
	}

	modelManagingHandler(operationId, eventType): void {
		const meshArray = +operationId === 1 ? SPRING_BALANCE : [...SPRING_BALANCE, ...REVERSE_HANDLE];

		const { optional, description, ...getCurrentData } = this.serverOperationData.find((x) => x.id === +operationId);
		const hasReverse = !_.isEmpty(this.storageData.operation.optional.find((x) => x.id === 2));
		const reverseLock = optional.find((x) => x.id === 2);

		this.engineService.setReverse({
			meshes: REVERSE_HANDLE,
			state: hasReverse,
			type: eventType,
			description: reverseLock?.description,
		});

		this.engineService.setOperation({
			id: operationId,
			meshes: meshArray,
			type: eventType,
			description,
		});

		if (+this.storageData.operation.id !== +operationId) {
			this.storageData.operation = {
				...this.storageData.operation,
				...getCurrentData,
			};
			this.storageData.operation.optional = [];
			this.shareDataService.setOperationName(this.storageData.operation.name);
			this.saveBlindSetup();
		}

		this.checkingRadio(getCurrentData);
	}

	onSubmitCheckboxOptions(event): void {
		const currentOption = $(event.currentTarget);
		const isChecked = !currentOption.siblings(SELECTORS.operation_input).is(':checked');
		const isParentChecked = currentOption.parents(this.selectors.list).hasClass('checked');
		const optionID = currentOption.parents(this.selectors.list).attr('id');

		if (!isParentChecked) {
			currentOption.parents(this.selectors.list).find(SELECTORS.operation_label).trigger('click');
		}

		const optionalCheckboxId = currentOption.attr('id');
		const getOptionalData = this.serverOperationData.find((x) => x.id === +optionID).optional;
		const getOptionalDataItem = getOptionalData.find((y) => y.id === +optionalCheckboxId);

		if (_.isEmpty(this.storageData.operation.optional)) {
			this.storageData.operation.optional.push(getOptionalDataItem);
		} else {
			const hasCurrentItem = !_.isEmpty(this.storageData.operation.optional.filter((x) => x.id === getOptionalDataItem.id));

			if (this.storageData.operation.optional.length === getOptionalData.length && isChecked) {
				if (!hasCurrentItem) {
					this.storageData.operation.optional.push(getOptionalDataItem);
				}
			} else if (hasCurrentItem) {
				this.storageData.operation.optional.forEach((item, index) => {
					if (item.id === getOptionalDataItem.id) {
						this.storageData.operation.optional.splice(index, 1);
					}
				});
			} else {
				this.storageData.operation.optional.push(getOptionalDataItem);
			}
		}

		if (getOptionalDataItem.id === 2) {
			this.engineService.setReverse({
				meshes: REVERSE_HANDLE,
				state: !$(event.currentTarget).prev().prop('checked'),
				type: event.type,
				description: getOptionalDataItem.description,
			});
		}

		this.saveBlindSetup();
	}

	setBlindSelectId(data): void {
		this.blindType = this.sessionStorageService.getBlindItemById(data?.id, STORAGE_NAMES.zip_blind_data)[0]?.type || this.blindType;

		if (data.id === this.blindId) {
			this.serverOperationData = this.sessionConfig[this.blindType].frame.operation;
			this.getStorageOperation();
		}

		this.changeDetection.markForCheck();
	}

	setDefaultValue(): void {
		const frameConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config)[this.blindType].frame;
		const materialStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data);

		if (materialStorage && materialStorage[0].setup && materialStorage[0].setup.operation) {
			return;
		}
		for (const item of frameConfig.operation) {
			if (item.is_default) {
				this.modelManagingHandler(item.id, 'loading');
				this.defaultId = item.id;
			}
		}
	}

	checkingRadio(operationData): void {
		const inputId = `${this.blindId}${operationData.name.split(' ').join('')}${this.random}`;

		$(`#${inputId}`).prop('checked', true);
	}

	onPopover(popover: NgbPopover, button: HTMLElement): void {
		$(button).toggleClass('active', popover.isOpen());
	}

	saveBlindSetup(): void {
		this.saveSessionBlind.PutStorageDataToServer();
		this.operationData.emit(this.storageData);
		this.sessionStorageService.setBlindSetupDataById(this.blindId, this.storageData, STORAGE_NAMES.zip_blind_data);
	}
}
