<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="closeDialog()"></div>
	<div class="mc-modal__title" [ngClass]="{ 'to-portrait': data === rotateTypes.to_portrait }">
		{{ 'modals.rotate_device.title' | translate }}
	</div>
	<div class="mc-modal__subtitle" [innerHTML]="'modals.rotate_device.subtitle' | translate: { orientation: orientation }"></div>
	<div class="mc-modal__button mc-modal__button--yellow" (click)="resetScene()">
		{{ 'modals.rotate_device.button' | translate }}
	</div>
</div>
