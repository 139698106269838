import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	AfterViewInit,
	HostListener,
	Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { CONFIG, STORAGE_NAMES, BREAKPOINTS, VIEW_TYPES, CLASSES } from '@root/app.config';
import { Breakpoints } from '@root/app.interfaces';
import 'simplebar';

declare const $: any;

@Component({
	selector: 'app-tool-bar',
	templateUrl: './tool-bar.component.html',
	styleUrls: ['./tool-bar.component.scss', './tool-bar.responsive.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolBarComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() isImageVisualisation: boolean;
	@ViewChild('toolBarContainer', { read: ElementRef })
	public toolBarContainer: ElementRef<any>;
	@ViewChild('mobileToolBarContainer', { read: ElementRef })
	public mobileToolBarContainer: ElementRef<any>;

	getModelLoadedStatus: Subscription;
	getLoadingSpinner: Subscription;
	getViewType: Subscription;
	simpleBarStatus: boolean;
	status = true;
	isModelLoaded: string;
	isToolBarCollapsed = false;
	breakpoints: Breakpoints = BREAKPOINTS;

	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange() {
		const isLandscape = screen.orientation.type.includes('landscape');
		const width = [window.innerWidth, window.innerHeight].sort((a, b) => a - b)[+isLandscape];

		this.isToolBarCollapsed &&= width >= +this.breakpoints.desktop.min;
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.simpleBarStatus = window.innerWidth >= CONFIG.breakpoints['tablet-landscape'];
	}

	constructor(
		private shareDataService: ShareService,
		private sessionStorageService: SessionStorageService,
		private changeDetection: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.getModelLoadedStatus = this.shareDataService.getSceneCreated.subscribe((res) => (this.isModelLoaded = res));

		this.setLoadingHandler();
		this.viewTypeHandler();
		this.onResize();
	}

	ngAfterViewInit() {
		this.getLoadingSpinner = this.shareDataService.getLoadingSpinner.subscribe((res) => {
			setTimeout(() => {
				(this.toolBarContainer?.nativeElement || this.mobileToolBarContainer?.nativeElement).classList.toggle(CLASSES.loading, res);
			});

			this.changeDetection.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.getModelLoadedStatus.unsubscribe();
		this.getLoadingSpinner.unsubscribe();
		this.getViewType.unsubscribe();
	}

	setLoadingHandler(): void {
		const savedStatus = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved) || window.location.search;

		if (savedStatus) {
			setTimeout(() => {
				$(this.toolBarContainer?.nativeElement || this.mobileToolBarContainer?.nativeElement).addClass(CLASSES.loading);
			});
		}
	}

	collapseToolBar(): void {
		this.isToolBarCollapsed = !this.isToolBarCollapsed;
	}

	viewTypeHandler(): void {
		this.getViewType = this.shareDataService.getViewType.subscribe((type: string) => {
			const isDesign = type === VIEW_TYPES.design;

			if (isDesign && this.isToolBarCollapsed) {
				this.collapseToolBar();
			}
		});
	}
}
