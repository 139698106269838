<div class="mc-fixtures__container">
	<div #fixturesList class="mc-fixtures__list">
		<div class="mc-fixtures__block"
			 *ngFor="let item of serverFixturesData; let i = index"
			 id="{{item.id}}"
			 [class]="{'active': item.id === storageData.fixtures_color.id}"
			 (click)="onSubmitRadioOptions($event, item.id)">
			<div class="mc-fixtures__block-circle"
				 [ngStyle]="{'background': 'linear-gradient(315deg,' + item.color +' 58.49%, ' + item.color + '80 100%)',
                                 'box-shadow': 'inset -4px -4px 20px rgba(0, 0, 0, 0.2)'}"></div>
			<div class="mc-fixtures__block-title">
				{{ item.name }}
			</div>
		</div>
	</div>
</div>
