import { Component, OnDestroy, OnInit } from '@angular/core';
import { SELECTORS } from '@root/app.config';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EngineService } from '@core/services/engine/engine.service';

declare const $: any;

@Component({
	selector: 'app-save-work-email-modal',
	templateUrl: './save-work-email-modal.component.html',
	styleUrls: ['./save-work-email-modal.component.scss', './save-work-email-modal.responsive.scss'],
})
export class SaveWorkEmailModalComponent implements OnInit, OnDestroy {
	submitted: boolean;
	saving: boolean;
	emailSent: boolean;
	emailRegex = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$');
	form = this.fb.group({
		email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
	});

	get f() {
		return this.form.controls;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private matDialog: MatDialog,
		private engineService: EngineService,
		private saveSessionBlind: SaveSessionBlindsService,
		public dialogRef: MatDialogRef<SaveWorkEmailModalComponent>,
	) {}

	ngOnInit() {
		this.engineService.setGizmoControlVisible(false);
		$(SELECTORS.overlay_container).addClass('internal');
	}

	ngOnDestroy() {
		this.engineService.setGizmoControlVisible(true);
		$(SELECTORS.overlay_container).removeClass('internal');
	}

	onSubmit(): void {
		this.submitted = true;

		if (this.form.valid) {
			this.saving = true;
			this.dialogRef.disableClose = true;

			this.saveSessionBlind.putSaveBlindsPromise(this.form.value.email).then(() => {
				this.dialogRef.disableClose = false;
				this.saving = false;
				this.emailSent = true;
			});
		}
	}

	close(): void {
		this.dialogRef.close();
	}
}
