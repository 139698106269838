import { Injectable } from '@angular/core';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ROTATE_TYPES, SELECTORS, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { BackgroundConfig } from '@root/app.interfaces';
import { IndexedDBService } from '@core/services/indexed-db/indexed-db.service';
import { RotateDeviceModalComponent } from '@shared/modals/rotate-device-modal/rotate-device-modal.component';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalService } from '@core/services/modal/modal.service';

@Injectable({
	providedIn: 'root',
})
export class BackgroundService {
	isMobile: boolean = this.passDataService.isMobileOrTablet;
	backgroundData: BackgroundConfig;

	constructor(
		private passDataService: PassDataService,
		private matDialog: MatDialog,
		private modalService: ModalService,
		private sessionStorageService: SessionStorageService,
		private indexedDBService: IndexedDBService,
	) {
		this.orientationChangeHandler();
	}

	orientationChangeHandler(): void {
		window.onorientationchange = () => {
			const isImageVisualisation = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.image_visualisation;
			const isSampleProject = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_iv_type) === 'project_sample';

			if (isImageVisualisation) {
				const orientation = this.getScreenOrientation();
				const images = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config)?.iv_images;

				if (isSampleProject && !this.passDataService.isLiveOpened) {
					this.openRotateDeviceModal();

					this.backgroundData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background);
					const splitId = this.backgroundData.id.split('_');
					splitId.pop();
					splitId.push(orientation);
					const id = splitId.join('_');

					this.indexedDBService.getImage(STORAGE_NAMES.zip_image_visualisation_background, id).then((image) => {
						if (image) {
							window.URL.revokeObjectURL(image.src);
							this.uploadFile(image.blob);
						} else if (orientation !== this.backgroundData.image_orientation) {
							const imageUrl = images[this.backgroundData.type][this.backgroundData.index].replace('/preview/', `/${orientation}_`);
							this.uploadImage(imageUrl);
						}
					});
				}
			}
		};
	}

	getSampleProjectImage(currentBackgroundImageId): Promise<any> {
		this.backgroundData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background);

		return new Promise((resolve) => {
			const uploadFile = (file: File | Blob) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => (this.backgroundData.base64 = reader.result);

				const blob = new Blob([file]);
				const image = new Image();

				image.src = window.URL.createObjectURL(blob);
				image.onload = () => {
					const { width, height, src } = image;

					this.backgroundData = {
						...this.backgroundData,
						src,
						width,
						height,
						image_orientation: this.getScreenOrientation(),
						id: `${this.backgroundData.type}_${this.backgroundData.index}_${this.getScreenOrientation()}`,
						blob: file,
					};

					this.indexedDBService.saveImage(this.backgroundData, STORAGE_NAMES.zip_image_visualisation_background);
					resolve(this.backgroundData);
				};
			};

			const images = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config)?.iv_images;
			const [type, index, orientation] = currentBackgroundImageId.split('_');

			const imageUrl = images[type][index].replace('/preview/', `/${orientation}_`);

			fetch(imageUrl)
				.then((res) => res.blob())
				.then((blob) => uploadFile(blob))
				.catch((err) => console.error(err));
		});
	}

	uploadIVFile(file: File | Blob) {
		return new Promise((resolve) => {
			const imageProperties = {};
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				imageProperties['base64'] = reader.result;
			};

			const image = new Image();
			image.src = window.URL.createObjectURL(file);
			image.onload = () => {
				imageProperties['src'] = image.src;
				imageProperties['blob'] = file;
				imageProperties['height'] = image.height;
				imageProperties['width'] = image.width;

				resolve(imageProperties);
			};
		});
	}

	async getIVImageFromSettings(imageSrc): Promise<any> {
		return fetch(imageSrc)
			.then((res) => res.blob())
			.then((blob) => this.uploadIVFile(blob))
			.catch((err) => console.error(err));
	}

	uploadImage(url: string): void {
		this.loaderSwitcher(true);
		fetch(url)
			.then((res) => res.blob())
			.then((blob) => this.uploadFile(blob))
			.catch((err) => console.error(err));
	}

	uploadFile(file: File | Blob) {
		const image = new Image();

		image.src = window.URL.createObjectURL(file);
		image.onload = () => {
			const { width, height, src } = image;

			this.backgroundData = {
				...this.backgroundData,
				src,
				width,
				height,
				image_orientation: this.getScreenOrientation(),
				id: `${this.backgroundData.type}_${this.backgroundData.index}_${this.getScreenOrientation()}`,
				blob: file,
			};

			const backgroundConfig = { ...this.backgroundData };
			delete backgroundConfig.base64;

			this.sessionStorageService.setBlindData(backgroundConfig, STORAGE_NAMES.zip_image_visualisation_background);

			this.loaderSwitcher(false);
			this.setBackground(src);

			this.indexedDBService.saveImage(this.backgroundData, STORAGE_NAMES.zip_image_visualisation_background);
		};
	}

	getScreenOrientation(): string {
		return window.orientation === 0 || window.orientation === 180 ? 'portrait' : 'landscape';
	}

	loaderSwitcher(status: boolean): void {
		document.querySelector(SELECTORS.model_viewer).classList.toggle('loader', status);
	}

	setBackground(image: string): void {
		const canvas = document.getElementById(SELECTORS.render_canvas) as HTMLElement;
		canvas.style.backgroundImage = `url(${image})`;
	}

	openRotateDeviceModal(): void {
		const isOpen = this.matDialog.getDialogById('rotate-device-modal');
		const orientation = this.getScreenOrientation();
		const rotateType = orientation === 'landscape' ? ROTATE_TYPES.to_portrait : ROTATE_TYPES.to_landscape;

		if (this.isMobile && !isOpen) {
			this.matDialog.open(RotateDeviceModalComponent, this.modalService.getConfig(RotateDeviceModalComponent.name, rotateType));
		}
	}
}
