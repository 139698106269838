import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ModalService } from '@core/services/modal/modal.service';
import { Countries, SELECTORS, STORAGE_NAMES } from '@root/app.config';
import { Country } from '@root/app.interfaces';
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';

@Component({
	selector: 'app-country-selector',
	templateUrl: './country-selector.component.html',
	styleUrls: ['./country-selector.component.scss', './country-selector.responsive.scss'],
})
export class CountrySelectorComponent implements OnInit, OnDestroy {
	@ViewChild('matSelect') matSelect: MatSelect;

	readonly countries = Countries;
	selectedCountry: Country = null;
	isOpen: boolean;

	getCountryCodeSubscription: Subscription;

	@HostListener('window:click', ['$event']) onClickEvent(event: Event) {
		const target = event.target as HTMLElement;

		if (this.isOpen && !target.closest(SELECTORS.country_selector)) {
			this.matSelect.close();
		}
	}

	constructor(
		private sessionStorageService: SessionStorageService,
		private shareDataService: ShareService,
		private modalService: ModalService,
	) {}

	ngOnInit() {
		this.getSelectedCountry();
	}

	ngOnDestroy() {
		this.getCountryCodeSubscription?.unsubscribe();
	}

	getSelectedCountry() {
		if (this.getCountryCodeFromStorage()) {
			this.selectedCountry = this.getCountryFormConfig(this.getCountryCodeFromStorage());
		} else {
			this.getCountryCodeSubscription = this.shareDataService.getCountry.subscribe((code) => {
				this.selectedCountry = this.getCountryFormConfig(code);
			});
		}
	}

	getCountryCodeFromStorage(): string | null {
		return this.sessionStorageService.getSession(STORAGE_NAMES.zip_country_code);
	}

	getCountryFormConfig(code: string): Country {
		return Countries.filter((country) => country.code === code)[0];
	}

	onToggle(isOpen: boolean) {
		this.isOpen = isOpen;
		['internal', 'untouchable'].forEach((className) =>
			document.querySelector(SELECTORS.overlay_container).classList.toggle(className, isOpen),
		);
	}

	onClick() {
		document.querySelector(SELECTORS.overlay_container).classList.toggle('internal', !this.isOpen);
	}

	openConfirmationModal(country: Country) {
		this.modalService
			.openResponsiveDialog({
				...{ component: ConfirmModalComponent, data: { page: 'change_country' } },
				...this.modalService.getConfig(ConfirmModalComponent.name),
			})
			.afterClosed()
			.subscribe((closeStatus: boolean) => {
				const prev = this.selectedCountry;
				this.selectedCountry = null;
				document.querySelector(SELECTORS.overlay_container).classList.add('untouchable');

				setTimeout(() => {
					this.selectedCountry = closeStatus ? country : prev;

					if (closeStatus) {
						this.sessionStorageService.setSession(this.selectedCountry.code, STORAGE_NAMES.zip_country_code);
						this.shareDataService.resetCountry(this.selectedCountry);
					}
				});
			});
	}
}
