<div #modelContainer class="mc-model-viewer-wrapper"
	 [class]="{
		'image-visualisation': isImageVisualisation,
		'interior': currentBlindType === 'interior',
		'loader': loading
	 }">
	<div class="mc-scroll-space"></div>

	<canvas #rendererCanvas id="renderCanvas" [ngClass]="{'mobile': isMobile}"></canvas>

	<div #cursorMove class="mc-cursor__container mc-cursor__move" [ngClass]="{'mobile': hasTouch, 'hidden': loading}">
		<div class="mc-cursor__text">
			{{ 'model_viewer.hints.' + (hasTouch ? 'move_mobile' : 'move') | translate }}
		</div>
	</div>

	<div #cursorZoomIn class="mc-cursor__container mc-cursor__zoom-in" [ngClass]="{'mobile': hasTouch, 'hidden': loading}">
		<div class="mc-cursor__text">
			{{ 'model_viewer.hints.' + (hasTouch ? 'zoom_in_mobile' : 'zoom_in') | translate }}
		</div>
	</div>

	<div #cursorZoomOut class="mc-cursor__container mc-cursor__zoom-out" [ngClass]="{'mobile': hasTouch, 'hidden': loading}">
		<div class="mc-cursor__text">
			{{ 'model_viewer.hints.' + (hasTouch ? 'zoom_out_mobile' : 'zoom_out') | translate }}
		</div>
	</div>

	<div #cursorDrag class="mc-cursor__container mc-cursor__drag">
		<div class="mc-cursor__text">{{ 'model_viewer.hints.drag' | translate }}</div>
	</div>

	<div #cursorRotate class="mc-cursor__container mc-cursor__rotate">
		<div class="mc-cursor__text">{{ 'model_viewer.hints.rotate' | translate }}</div>
	</div>
</div>
