import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { EngineService } from '@core/services/engine/engine.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { SELECTORS, STORAGE_NAMES } from '@root/app.config';
import { ModalService } from '@core/services/modal/modal.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { Subscription } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ArLoadingModalComponent } from '@shared/modals/ar-loading-modal/ar-loading-modal.component';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ArNoBlindsModalComponent } from '@shared/modals/ar-no-blinds-modal/ar-no-blinds-modal.component';


type FunctionCallback = <T>(argument: T | undefined) => void;
type ModelViewer = {
	activateAR: () => void
}

@Component({
	selector: 'app-augmented-reality',
	templateUrl: './augmented-reality.component.html',
	styleUrls: ['./augmented-reality.component.scss', './augmented-reality.responsive.scss']
})
export class AugmentedRealityComponent implements OnInit, OnDestroy {
	getModelLoaded: Subscription;
	getSceneCreated: Subscription;
	getCurrentBlindData: Subscription;
	
	modelViewer = document.querySelector(SELECTORS.ar_viewer) as HTMLElement & ModelViewer;
	isModelLoaded: boolean;
	isProd = this.passDataService.isProd;
	aRModalDialog: MatDialogRef<ArLoadingModalComponent>;
	savedModelName: string | null;
	unsupportedBrowsers = ['Firefox', 'Edg', 'FxiOS'];
	
	constructor(
		private engineService: EngineService,
		private passDataService: PassDataService,
		private sessionStorageService: SessionStorageService,
		private modalService: ModalService,
		private matDialog: MatDialog,
		private shareService: ShareService,
		private serverDataService: ServerDataService,
		private changeDetection: ChangeDetectorRef
	) {
	}
	
	ngOnInit(): void {
		this.modelRenderedHandler();
	}
	
	ngOnDestroy(): void {
		this.getModelLoaded.unsubscribe();
		this.getSceneCreated.unsubscribe();
		this.getCurrentBlindData.unsubscribe();
		this.removeSavedModel();
	}
	
	openAR(): void {
		const config = this.modalService.getConfig(ArLoadingModalComponent.name);
		const isUnsupportedBrowser = this.unsupportedBrowsers.some(browser => navigator.userAgent.includes(browser));
		
		if (!this.engineService.getRootMeshEnabled()) {
			this.matDialog.closeAll();
			this.modalService.openResponsiveDialog({
				component: ArNoBlindsModalComponent,
				...this.modalService.getConfig(ArNoBlindsModalComponent.name)
			});

			return;
		}
		
		config.data = { model_exported: false };
		this.aRModalDialog = this.matDialog.open(ArLoadingModalComponent, config);
		
		if (isUnsupportedBrowser && !this.passDataService.isProd) {
			this.openLive();
			return;
		}

		if (isUnsupportedBrowser && this.passDataService.isProd) {
			this.aRModalDialog.afterOpened().subscribe(() => {
				this.setNoCompatibleStatus();
			});
			return;
		}
		
		this.engineService.getModelGLB().then((model: Blob) => {
			this.aRModalDialog.componentInstance.data.model_exported = true;
			
			if (this.passDataService.isLaunchCameraStopped) {
				return;
			}
			
			if (this.iOS()) {
				const url = window.URL.createObjectURL(model);
				this.modelViewer.setAttribute('src', url);
			} else {
				this.saveModel(model);
			}
			
			this.onLoadModelViewerHandler();
			this.modelViewerStatusHandler();
			this.webXRCloseHandler();
			this.onCloseLoadingModal();
		}).catch(error => {
			this.closeModals();
			console.error(error);
		});
	}
	
	getPageVisibilityChange(): Observable<Event> {
		let hidden: string = 'hidden';
		let eventType: string;
		
		if (hidden in document) {
			eventType = 'visibilitychange';
		} else if ((hidden = 'mozHidden') in document) {
			eventType = 'mozvisibilitychange';
		} else if ((hidden = 'webkitHidden') in document) {
			eventType = 'webkitvisibilitychange';
		} else if ((hidden = 'msHidden') in document) {
			eventType = 'msvisibilitychange';
		} else {
			window.onpageshow = window.onpagehide
				= window.onfocus = window.onblur = onchange;
		}
		
		return fromEvent(document, eventType);
	}
	
	openLive(): void {
		this.shareService.setARStatus('loading');
		this.engineService.addVideoLayer().then((status: boolean) => {
			if (status) {
				this.closeModals();
			}
		}).catch(err => {
			this.aRModalDialog.close();
		});
	}
	
	closeModals(): void {
		this.runFunctionWithDelay(this.matDialog.closeAll.bind(this.matDialog));
	}
	
	runFunctionWithDelay(func: FunctionCallback): void {
		setTimeout(func.bind(this), 500);
	}
	
	iOS(): boolean {
		return [
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			].includes(navigator.platform)
			|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
	}

	closeARHandler(): void {
		this.getPageVisibilityChange().subscribe(() => {
			const isHidden = document.hidden || document.visibilityState === 'hidden';

			if (isHidden && !this.passDataService.isLiveOpened) {
				this.setViewType();
				this.closeModals();
			}

			if (!isHidden && this.passDataService.isLiveOpened) {
				this.shareService.setARStatus('loading');
				setTimeout(this.openLive.bind(this), 500);
			}
		});
	}

	setViewType(): void {
		const type = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type);
		this.shareService.setViewType(type);
	}

	setNoCompatibleStatus(): void {
		this.shareService.setARStatus('not-compatible');
	}

	modelRenderedHandler(): void {
		const onModelLoaded = () => {
			this.isModelLoaded = true;
			this.changeDetection.detectChanges();
		}

		this.getSceneCreated = this.shareService.getSceneCreated.subscribe(onModelLoaded.bind(this));
		this.getCurrentBlindData = this.shareService.currentData.subscribe(() => this.isModelLoaded = false);
		this.getModelLoaded = this.shareService.getModelLoaded.subscribe(onModelLoaded.bind(this));

	}

	onLoadModelViewerHandler(): void {
		this.modelViewer.addEventListener('load', () => {
			if (this.passDataService.isLaunchCameraStopped) {
				return;
			}

			if (!this.passDataService.isLaunchCameraStopped && this.iOS()) {
				this.aRModalDialog.componentInstance.data.model_exported = false;
				this.engineService.closeVideoStream();
			}

			if (this.iOS()) {
				this.passDataService.isLaunchCameraStopped = false;
			}

			this.shareService.setARStatus('load');
			this.closeARHandler();
		});
	}

	modelViewerStatusHandler(): void {
		this.modelViewer.addEventListener('ar-status', (e: CustomEvent) => {
			if (!this.iOS() && !this.passDataService.isLaunchCameraStopped && e.detail.status === 'session-started') {
				this.engineService.closeVideoStream();
			}

			if (e.detail.status === 'failed') {
				this.passDataService.isProd ? this.setNoCompatibleStatus() : this.openLive();
				console.error(`ar-status: ${ e.detail.status }`);
			}
			;
		});
	}

	webXRCloseHandler(): void {
		this.modelViewer.shadowRoot.querySelector(SELECTORS.ar_close_button).addEventListener('click', () => {
			if (this.passDataService.isLiveOpened) {
				this.openLive();
			} else {
				this.setViewType();
			}

			this.closeModals();
		});
	}

	saveModel(model: Blob): void {
		const reader = new FileReader();
		reader.readAsDataURL(model);
		reader.onload = () => {
			this.serverDataService.PostModelData({ model: reader.result }).subscribe(res => {
				this.savedModelName = res.name;
				this.modelViewer.setAttribute('src', res.link);
				this.modelViewer.setAttribute('ar-modes', 'scene-viewer webxr');
				this.resetOnBeforeUnloadHandler();
			});
		};
	}

	onCloseLoadingModal(): void {
		this.aRModalDialog.afterClosed().subscribe(() => {
			this.removeSavedModel();
			this.shareService.setCheckExit(true);
		});
	}

	removeSavedModel(): void {
		if (this.savedModelName) {
			this.serverDataService.deleteModelData(this.savedModelName).subscribe(() => {
				this.savedModelName = null;
				this.modelViewer.setAttribute('src', '');
			});
		}
	}
	
	resetOnBeforeUnloadHandler(): void {
		window.onbeforeunload = () => {};
	}

}
