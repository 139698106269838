<div class="mc-control-bar">
	<ul class="mc-control-bar__items">
		<li
			class="mc-control-bar__item mc-control-bar__item--reset"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="bottom"
			popoverClass="mc-popover mc-popover-image-visualisation reset"
			[ngClass]="{ active: resetPopover?.isOpen(), disabled: !resetStatus }"
			[ngbPopover]="!isMobile ? ('control_panel.reset' | translate) : null"
		>
			<div
				class="mc-control-bar__item-inside"
				#resetPopover="ngbPopover"
				container="body"
				[style.display]="resetStatus ? 'block' : 'none'"
				(click)="$event.stopPropagation()"
				[placement]="resetPopoverPosition"
				[ngbPopover]="resetIVScreen"
				popoverClass="mc-popover mc-popover-reset"
			></div>
		</li>

		<li
			class="mc-control-bar__item mc-control-bar__item--background"
			(click)="onBackgroundSelectControl($event)"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="bottom"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="!isMobile ? ('control_panel.background' | translate) : null"
		></li>

		<li
			class="mc-control-bar__item mc-control-bar__item--save"
			(click)="onSaveControl($event)"
			#popover="ngbPopover"
			triggers="mouseenter:mouseleave"
			placement="bottom"
			popoverClass="mc-popover mc-popover-image-visualisation save"
			[ngbPopover]="!isMobile ? ('control_panel.save' | translate) : null"
		></li>
	</ul>

	<ng-template #resetIVScreen>
		<div class="mc-control-bar__reset-title">
			{{ 'modals.reset_IV.title' | translate }}
		</div>
		<button class="mc-control-bar__button yes" (click)="onResetIVScreen()">
			{{ 'modals.reset_IV.buttons.yes' | translate }}
		</button>
		<button class="mc-control-bar__button no" (click)="[resetPopover.close(), $event.stopPropagation()]">
			{{ 'modals.reset_IV.buttons.no' | translate }}
		</button>
	</ng-template>
</div>
