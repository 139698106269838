import { ChangeDetectorRef, Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { BlindListComponent } from '@features/blind-list/blind-list.component';
import { ShareService } from '@core/services/share-data/share-data.service';
import { EngineService } from '@core/services/engine/engine.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ActivatedRoute } from '@angular/router';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { ScreenshotToBlindService } from '@core/services/screenshot-to-blind/screenshot-to-blind.service';

@Component({
	selector: 'app-outdoor-list',
	templateUrl: './outdoor-list.component.html',
	styleUrls: ['../blind-list.component.scss', '../blind-list.responsive.scss'],
})
export class OutdoorListComponent extends BlindListComponent implements OnInit, OnChanges {
	@Input() index: number;
	@Input() blindsListComponent: BlindListComponent;

	@Output() setupData = new EventEmitter();

	blind: any;

	constructor(
		public shareDataService: ShareService,
		public engineService: EngineService,
		public serverDataService: ServerDataService,
		public saveSessionBlind: SaveSessionBlindsService,
		public route: ActivatedRoute,
		public screenShotService: ScreenshotService,
		public screenshotToBlindService: ScreenshotToBlindService,
		public sessionStorageService: SessionStorageService,
		public passDataService: PassDataService,
		public changeDetection: ChangeDetectorRef,
	) {
		super(
			shareDataService,
			engineService,
			serverDataService,
			saveSessionBlind,
			route,
			screenShotService,
			screenshotToBlindService,
			sessionStorageService,
			passDataService,
			changeDetection,
		);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.blind = this.blindsData[this.index];

		setTimeout(this.blindNameSave.bind(this, this.blind.blind_id));
		this.getModelLoadedStatus.unsubscribe();
		this.getScreenShot.unsubscribe();
		this.getBlindsData.unsubscribe();
		this.getSelectedModel.unsubscribe();
		this.getAccordionMobileBlindList.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.index.firstChange) {
			this.blind = this.blindsListComponent.blindsData[this.index];
			this.blindsData = this.blindsListComponent.blindsData;
		}
	}

	blindNameSave(id: any): void {
		super.blindNameSave(id);
		if (this.blindsListComponent.blindsData) {
			this.blindsListComponent.blindsData[this.index].name = this.blind.name;
		}
	}

	onBlindItemDescriptionHandler(event: Event, id?: number): void {
		super.onBlindItemDescriptionHandler(event, id);
		this.setupData.emit(event);
	}
}
