import { Component,
	OnInit,
	OnDestroy,
	Inject,
	HostListener,
	ViewChild,
	ChangeDetectorRef
} from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SELECTORS, BREAKPOINTS } from '@root/app.config';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@core/services/modal/modal.service';
import { Breakpoints } from '@root/app.interfaces';
import { SlickCarouselComponent, SlickItemDirective } from 'ngx-slick-carousel';

declare var $: any;

@Component({
	selector: 'app-ar-loading-modal',
	templateUrl: './ar-loading-modal.component.html',
	styleUrls: ['./ar-loading-modal.component.scss', 'ar-loading-modal.responsive.scss']
})
export class ArLoadingModalComponent implements OnInit, OnDestroy {
	@ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;

	getARStatus: Subscription;
	isArLoaded = false;
	isARNotCompatible = false;
	loading = true;
	quizOpened = false;
	quizIconsFolder: string = '/assets/icons/quiz/';
	breakpoints: Breakpoints = BREAKPOINTS;

	slidesQuantity: number;
	currentSlideIndex: number;
	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		infinite: false,
		swipe: true,
		arrows: false,
		autoplay: false,
		adaptiveHeight: false,
		customPaging: () => {
			return '<div class="slick-dot-custom"></div>';
		},
	};
	quizSlideTypes = ['surface', 'wait', 'move', 'scale'];

	constructor(
		private matDialogRef: MatDialogRef<ArLoadingModalComponent>,
		private matDialog: MatDialog,
		private passDataService: PassDataService,
		private shareService: ShareService,
		private translateService: TranslateService,
		private modalService: ModalService,
		private changeDetection: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data
	) {
	}

	@HostListener('window:orientationchange', ['$event'])
	@HostListener('window:resize', ['$event'])
	onOrientationChange() {
		if (this.slickCarousel?.initialized) {
			this.setImageHeight();
		}
	}
	
	ngOnInit(): void {
		this.passDataService.isLaunchCameraStopped = false;
		this.arStatusHandler();
		this.modalService.liftUpModal(this.matDialogRef);
	}

	ngOnDestroy(): void {
		this.getARStatus.unsubscribe();
		$(SELECTORS.ar_viewer).removeClass('open');
	}

	onClose(type: string): void {
		this.passDataService.isLaunchCameraStopped = true;
		type === 'close' ? this.matDialogRef.close() : this.matDialog.closeAll();
		$(SELECTORS.ar_viewer).removeClass('open');
	}

	arStatusHandler(): void {
		let mainARButtonText: string;
		
		this.getARStatus = this.shareService.getARStatus.subscribe((status: string) => {
			this.loading = status === 'loading';
			this.isARNotCompatible = status === 'not-compatible';
			this.isArLoaded = !['failed', 'not-presenting'].includes(status);

			if (!this.isArLoaded && !this.loading) {
				this.onClose(status);
			}

			if (this.isARNotCompatible) {
				mainARButtonText = $(SELECTORS.ar_button).text();
				$(SELECTORS.ar_button).attr('slot', '');
				$(SELECTORS.ar_viewer).addClass(status);
				$(SELECTORS.ar_button).text(this.translateService.instant('modals.ar_loading.close_button'));
				$(SELECTORS.ar_button).on('click', this.onClose.bind(this));
				this.data.model_exported = true;
			}

			this.quizOpened = this.isArLoaded && !this.isARNotCompatible;
		});
		
		this.matDialogRef.afterClosed().subscribe(() => {
			$(SELECTORS.ar_viewer).removeClass('open not-compatible');
			$(SELECTORS.ar_button).text(mainARButtonText);
			$(SELECTORS.ar_button).attr('slot', 'ar-button');
		});
	}

	onSkip(): void {
		this.slickCarousel.slickGoTo(this.slidesQuantity - 1);
	}

	carouselHandler(event: any): void {
		const slick = event.slick;

		if (event.event.type === 'init') {
			slick.$slider.find('img').first().on('load', this.setImageHeight.bind(this));
		}

		this.slidesQuantity = slick.slideCount;
		this.currentSlideIndex = slick.currentSlide;

		this.arButtonHandler();
		this.changeDetection.detectChanges();
	}

	setImageHeight(): void {
		const isLandscape = screen.orientation.type.includes('landscape');
		const slides = this.slickCarousel.slides;

		slides.forEach((slide: SlickItemDirective) => {
			const slideEl = slide.el.nativeElement;
			const imgWrapper = slideEl.querySelector('img').parentElement;
			const textHeight = slideEl.children[0].clientHeight;
			const slideHeight = slideEl.clientHeight;

			imgWrapper.style.height = isLandscape ? `${slideHeight - textHeight}px` : 'auto';
		});
	}

	arButtonHandler(): void {
		const arViewer = document.querySelector(SELECTORS.ar_viewer);
		const status = this.isArLoaded && (this.slidesQuantity -1 === this.currentSlideIndex);

		arViewer.classList.toggle('open', status);
	}

}
