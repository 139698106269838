<div class="mc-tool-bar"
	 [class]="{'offset-top': isImageVisualisation}">
	<ng-container *media="breakpoints['desktop'].min">
		<div class="mc-tool-bar__arrow"
			 [class]="{'collapsed': isToolBarCollapsed}"
			 (click)="collapseToolBar()"></div>
	</ng-container>

	<div class="mc-tool-bar__controls" [ngClass]="[status ? 'open' : 'close']">
		<div *ngIf="simpleBarStatus" data-simplebar data-simplebar-auto-hide="false"
			 class="mc-tool-bar__simple-bar"
			 [class]="{'collapsed': isToolBarCollapsed}">
			<div #toolBarContainer class="mc-tool-bar-accordion">
				<span class="loader"></span>
				<app-blind-list [isToolBarCollapsed]="isToolBarCollapsed" [isModelLoaded]="isModelLoaded"></app-blind-list>
			</div>
		</div>
		<div *ngIf="!simpleBarStatus" class="mc-tool-bar__simple-bar">
			<div #mobileToolBarContainer class="mc-tool-bar-accordion">
				<span class="loader"></span>
				<app-blind-list [isModelLoaded]="isModelLoaded"></app-blind-list>
			</div>
		</div>
	</div>
</div>
