<div class="mc-config-item mc-config-item__setup">
	<div class="mc-config-item__header">
		<div class="mc-config-item__header-title">Title</div>
		<div class="mc-config-item__header-content">
			<div class="mc-config-item__input" (click)="blindNameEdit(blind.blind_id, $event)">
				<input #newBlindName
					   type="text"
					   *ngIf="!blind.has_name"
					   value="{{blind.name || ('Blind ' + (index + 1))}}"
					   (keyup)="blindName(newBlindName.value, $event, blind.blind_id)"
					   (blur)="blindName(newBlindName.value, $event, blind.blind_id)"
					   class="mc-config-item__input-field"
					   placeholder="Enter blind name eg: Patio"/>

				<span *ngIf="blind.has_name" class="mc-config-item__header-name">{{ blind.name }}</span>
			</div>
		</div>
		<div class="mc-config-item__save" *ngIf="!blind.has_name" (click)="blindNameSave(blind.blind_id)">save</div>
		<div class="mc-config-item__edit" *ngIf="blind.has_name" (click)="blindNameEdit(blind.blind_id, $event)">edit
		</div>
	</div>
</div>
<div class="mc-config-item">
	<div class="mc-config-item__header"
		 (click)="accordionConfAction($event, '.mc-config-item', false)">
		<div class="mc-config-item__header-title">{{ sessionText.material_title }}</div>
		<div class="mc-config-item__header-content">
			<app-blind-item-description
					[blindItem]="blind.setup?.material || blindItemObject['material']"
					[blindItemType]="'material'"
			></app-blind-item-description>
		</div>
	</div>
	<div class="mc-config-item__content">
		<app-color-change
				[blindId]="blind.blind_id"
				(colorData)="onBlindItemDescriptionHandler($event, blind.blind_id)"
		></app-color-change>
	</div>
</div>
<div class="mc-config-item">
	<div class="mc-config-item__header"
		 (click)="accordionConfAction($event, '.mc-config-item', false)">
		<div class="mc-config-item__header-title">
			Size
			<span class="mc-info-icon-default"
				  #popover="ngbPopover" #popoverButton
				  (shown)="onPopover(popover, popoverButton)"
				  (hidden)="onPopover(popover, popoverButton)"
				  (click)="$event.stopPropagation()"
				  placement="right auto"
				  popoverClass="mc-popover mc-popover-blind-info"
				  [ngbPopover]="sessionText['size_pop_up_text']"></span>
		</div>
		<div class="mc-config-item__header-content">
			<app-blind-item-description [blindItem]="blind.setup?.size || blindItemObject['size']"
										[blindItemType]="'size'"
			></app-blind-item-description>
		</div>
	</div>
	<div class="mc-config-item__content">
		<app-size-change
				[blindId]="blind.blind_id"
				(sizeData)="onBlindItemDescriptionHandler($event, blind.blind_id)"
		></app-size-change>
	</div>
</div>
<div class="mc-config-item">
	<div class="mc-config-item__header"
		 (click)="accordionConfAction($event, '.mc-config-item', false)">
		<div class="mc-config-item__header-title">Frame</div>
		<div class="mc-config-item__header-content">
			<app-blind-item-description [blindItem]="blind.setup?.frames || blindItemObject['frames']"
										[blindItemType]="'frames'"
										[blindType]="'outdoor'"
			></app-blind-item-description>
		</div>
	</div>
	<div class="mc-config-item__content">
		<app-frame-change
				[blindId]="blind.blind_id"
				(frameData)="onBlindItemDescriptionHandler($event, blind.blind_id)"
		></app-frame-change>
	</div>
</div>
<div class="mc-config-item">
	<div class="mc-config-item__header"
		 (click)="accordionConfAction($event, '.mc-config-item', false)">
		<div class="mc-config-item__header-title">{{ sessionText.operation_title }}</div>
		<div class="mc-config-item__header-content">
			<app-blind-item-description [blindItem]="blind.setup?.operation || blindItemObject['operation']"
										[blindItemType]="'operation'"
										[blindType]="'outdoor'"
			></app-blind-item-description>
		</div>
	</div>
	<div class="mc-config-item__content">
		<app-operation-change
				[blindId]="blind.blind_id"
				(operationData)="onBlindItemDescriptionHandler($event, blind.blind_id)"
		></app-operation-change>
	</div>
</div>
<div class="mc-config-item">
	<div class="mc-config-item__header"
		 (click)="accordionConfAction($event, '.mc-config-item', false)">
		<div class="mc-config-item__header-title">{{ sessionText.mounting_title }}</div>
		<div class="mc-config-item__header-content">
			<app-blind-item-description [blindItem]="blind.setup?.mounting || blindItemObject['mounting']"
										[blindItemType]="'mounting'"
			></app-blind-item-description>
		</div>
	</div>
	<div class="mc-config-item__content">
		<app-mounting-change
				[blindId]="blind.blind_id"
				(mountingData)="onBlindItemDescriptionHandler($event, blind.blind_id)"
		></app-mounting-change>
	</div>
</div>
