<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="close()"></div>
	<img class="mc-modal__image"
		 [src]="imagesFolderUrl + 'select-blinds.png'"
		 alt="select-blinds-image">

	<div class="mc-modal__title">{{ 'modals.ar_no_blinds.title' | translate }}</div>

	<button class="mc-modal__button"
			type="button"
			(click)="onSelect()">{{ 'modals.ar_no_blinds.buttons.select' | translate }}</button>
</div>