<div class="mc-modal__content">
    <div class="mc-modal__close" (click)="closeDialog()"></div>
    <div class="mc-modal__icon"></div>
    <div class="mc-modal__title">{{ 'modals.another_device.title' | translate }}</div>
    <div class="mc-modal__subtitle">{{ 'modals.another_device.subtitle' | translate }}</div>
    <div class="mc-modal__buttons">
        <div class="mc-modal__button mc-modal__button--close" (click)="closeDialog()">
            {{ 'modals.another_device.buttons.close' | translate }}
        </div>
        <div class="mc-modal__button mc-modal__button--yellow" (click)="onContinue()">
            {{ 'modals.another_device.buttons.continue' | translate }}
        </div>
    </div>
</div>
